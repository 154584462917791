/* import __COLOCATED_TEMPLATE__ from './calling.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type TwilioService from 'embercom/services/twilio-service';
import type VideoCallService from 'embercom/services/video-call-service';
import type Session from 'embercom/services/session';
import { action } from '@ember/object';
import type IntercomCallService from 'embercom/services/intercom-call-service';
import { tracked } from '@glimmer/tracking';
import type Router from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type IntlService from 'embercom/services/intl';

interface Args {}

interface Signature {
  Args: Args;
}

export default class Calling extends Component<Signature> {
  @service declare twilioService: TwilioService;
  @service declare videoCallService: VideoCallService;
  @service declare session: Session;
  @service declare intercomCallService: IntercomCallService;
  @service declare router: Router;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare intl: IntlService;

  @tracked confirmedNavigation = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (
      this.session.workspace &&
      (this.session.workspace.isFeatureEnabled('channels-phone-call') ||
        this.session.workspace.isFeatureEnabled('inbound-phone-call'))
    ) {
      this.intercomCallService.getWorkspacePhoneNumbers();
      this.twilioService.initialize();
      this.router.on('routeWillChange', this.warnOfPossibleCallTermination);
    }
  }

  @action
  async warnOfPossibleCallTermination(transition: Transition) {
    if (!transition.to.name.startsWith('inbox.workspace')) {
      if (
        (this.twilioService.isActiveCall || // active call
          this.twilioService.shouldNotifyTeammate || // incoming call not yet answered
          this.twilioService.isListening) && // listening to a call
        !this.confirmedNavigation
      ) {
        transition.abort();
        let confirmed = await this.intercomConfirmService.confirm({
          title: this.intl.t('calling.navigate-away-warning.title'),
          body: this.intl.t('calling.navigate-away-warning.body'),
          confirmButtonText: this.intl.t('calling.navigate-away-warning.confirm'),
          cancelButtonText: this.intl.t('calling.navigate-away-warning.cancel'),
        });
        if (confirmed) {
          this.confirmedNavigation = true;
          this.rejectPendingCall();
          transition.retry();
        }
      }
    }
  }

  willDestroy() {
    super.willDestroy();
    this.twilioService.unregisterDevice();
    this.intercomCallService.closeDialler();
  }

  @action handleCallbackNexusEvent(event: any) {
    if (this.session.teammate.id === event.eventData.adminId) {
      this.twilioService.notifyInboundCallback(event);
    }
  }

  @action rejectPendingCall() {
    if (this.twilioService.shouldNotifyTeammate) {
      this.twilioService.rejectCall();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Calling': typeof Calling;
    'inbox2/calling': typeof Calling;
  }
}
