/* import __COLOCATED_TEMPLATE__ from './pane.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import { ChildAction } from 'embercom/objects/inbox/command-k/action';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type CreateLinkedTicketAction from 'embercom/objects/inbox/command-k/create-linked-ticket';
import { TicketTypeResource } from 'embercom/components/inbox2/ticket-type-resource';
import type CommandKService from 'embercom/services/command-k';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { type CommandKPaneComponentArgs } from 'embercom/lib/inbox2/command-k-types';
import { TicketCategory, type TicketType } from 'embercom/objects/inbox/ticket';
import { EmojiIcon, NamedIcon } from 'embercom/objects/inbox/command-k/icons';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type Router from '@ember/routing/router-service';
import type Session from 'embercom/services/session';

interface Signature {
  Args: CommandKPaneComponentArgs<CreateLinkedTicketAction>;
}

export default class CreateLinkedTicketPaneComponent extends Component<Signature> {
  @tracked query = '';
  @service declare commandK: CommandKService;
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare session: Session;
  @service declare intercomEventService: any;

  selection = useResource(this, NavigableSelection, () => ({
    items: this.items,
    isCommandKVisible: this.commandK.isVisible,
  }));

  ticketTypeResource = useResource(this, TicketTypeResource, () => ({
    allowedTicketCategories: this.allowedTicketCategories,
  }));

  get creatingNewTicket() {
    return this.args.actionItem.context?.newTicket;
  }

  get category() {
    return this.args.actionItem.context?.category as TicketCategory | undefined;
  }

  get allowedTicketCategories(): TicketCategory[] {
    if (this.creatingNewTicket) {
      return [TicketCategory.Request, TicketCategory.Task, TicketCategory.Tracker];
    }

    return [this.category ?? TicketCategory.Task];
  }

  get inputPlaceholder() {
    if (this.creatingNewTicket) {
      return this.intl.t(`inbox.command-k.actions.create-a-new-ticket`);
    }

    if (this.category === TicketCategory.Tracker) {
      return this.intl.t(`inbox.command-k.actions.create-tracker-ticket`);
    } else if (this.category === TicketCategory.Task) {
      return this.intl.t(`inbox.command-k.actions.create-task-ticket`);
    }

    return this.intl.t(`inbox.command-k.actions.${this.args.actionItem.id}`);
  }

  get icon() {
    if (!this.category) {
      return new NamedIcon('ticket');
    }

    return {
      [TicketCategory.Request]: new NamedIcon('ticket'),
      [TicketCategory.Tracker]: new NamedIcon('tracker'),
      [TicketCategory.Task]: new NamedIcon('back-office', 'standard'),
    }[this.category];
  }

  getTicketTypeIcon(ticketType: TicketType) {
    if (!ticketType.category) {
      return new NamedIcon('ticket');
    }

    return {
      [TicketCategory.Request]: new NamedIcon('ticket'),
      [TicketCategory.Tracker]: new NamedIcon('tracker'),
      [TicketCategory.Task]: new NamedIcon('back-office', 'standard'),
    }[ticketType.category];
  }

  emptyStateTranslation(key: string) {
    return {
      header: this.intl.t(`inbox.command-k.tickets.empty-state.header`, {
        ticket_type: this.intl.t(`inbox.command-k.tickets.singular.${key}`),
      }),
      linkText: this.intl.t(`inbox.command-k.tickets.empty-state.link-text`, {
        ticket_type: this.intl.t(`inbox.command-k.tickets.singular.${key}`),
      }),
      subText: this.intl.t(`inbox.command-k.tickets.empty-state.${key}`),
    };
  }

  get emptyStateInfo() {
    if (this.args.actionItem.context?.newTicket) {
      return {
        icon: 'ticket',
        ...this.emptyStateTranslation('ticket'),
      };
    }

    if (this.category === TicketCategory.Tracker) {
      return {
        icon: 'tracker',
        ...this.emptyStateTranslation('tracker'),
      };
    } else if (this.category === TicketCategory.Task) {
      return {
        icon: 'ticket',
        ...this.emptyStateTranslation('task'),
      };
    }

    return {};
  }

  get emptyTicketTypes() {
    return this.items.length === 0;
  }

  get items(): Array<ChildAction> {
    let upperQuery = this.query.trim().toLocaleUpperCase();

    return this.ticketTypeResource.activeTicketTypes
      .filter((ticketType) => ticketType.name.toLocaleUpperCase().includes(upperQuery))
      .sortBy('name')
      .map((ticketType) => {
        let { id, name } = ticketType;

        return new ChildAction({
          parent: this.args.actionItem,
          id: id.toString(),
          label: name,
          value: {
            id,
            category: ticketType.category,
            categoryIcon: this.getTicketTypeIcon(ticketType),
            newTicket: this.args.actionItem.context?.newTicket,
          },
          icon: new EmojiIcon(ticketType.emoji || undefined),
        });
      });
  }

  get itemsList() {
    let togetherList = [...this.items];
    togetherList.push(
      new ChildAction({
        parent: this.args.actionItem,
        id: '5000',
        label: this.intl.t('inbox.command-k.actions.manage-ticket-types'),
        value: {
          id: 'ticket-types-settings',
          category: undefined,
          categoryIcon: new NamedIcon('settings'),
          newTicket: false,
        },
        icon: new NamedIcon('settings'),
      }),
    );

    return togetherList;
  }

  get app() {
    return this.session.workspace;
  }

  get isLoading() {
    return this.ticketTypeResource.isLoading;
  }

  @action search(query: string) {
    this.query = query;

    this.commandK.trackSearch({
      query: this.query,
      number_of_results: this.items.length,
    });
  }

  @action settingsClicked() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'ticket_intervention_inbox_conversation_create_linked_ticket_popup_settings',
      place: 'inbox',
      context: 'inbox.conversation.create_linked_ticket_popup',
      section: 'create_linked_ticket_popup',
    });
    this.mangeTicketTypes();
  }

  @action mangeTicketTypes() {
    let url: string = this.router.urlFor('apps.app.settings.ticket-data', this.session.workspace);
    safeWindowOpen(url);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::CreateLinkedTicket::Pane': typeof CreateLinkedTicketPaneComponent;
    'inbox2/command-k/create-linked-ticket/pane': typeof CreateLinkedTicketPaneComponent;
  }
}
