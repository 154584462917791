/* import __COLOCATED_TEMPLATE__ from './suggestion-button.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-ai */
import { modifier } from 'ember-modifier';
import Component from '@glimmer/component';

interface SuggestionButtonArgs {
  suggestion: string;
  emoji?: string;
  onClick: () => void;
  onShow: () => void;
}

interface Signature {
  Element: Element;
  Args: SuggestionButtonArgs;
  Blocks: {
    default: [];
  };
}

export default class SuggestionButton extends Component<Signature> {
  onShow = modifier(
    () => {
      this.args.onShow();
    },
    { eager: false },
  );
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Suggestions::SuggestionButton': typeof SuggestionButton;
    'inbox2/copilot/suggestions/suggestion-button': typeof SuggestionButton;
  }
}
