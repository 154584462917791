/* import __COLOCATED_TEMPLATE__ from './pane.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import { ChildAction } from 'embercom/objects/inbox/command-k/action';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { useFunction } from 'ember-resources';
import type InsertArticleAction from 'embercom/objects/inbox/command-k/insert-article';
import { inject as service } from '@ember/service';
import { type AugmentedHelpCenterData } from 'embercom/services/articles-api';
import type ArticlesApi from 'embercom/services/articles-api';
import type CommandKService from 'embercom/services/command-k';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import type InboxState from 'embercom/services/inbox-state';
import { type CommandKPaneComponentArgs } from 'embercom/lib/inbox2/command-k-types';
import { trackedFunction } from 'ember-resources/util/function';

type HelpCentersLoaderResponse = Array<AugmentedHelpCenterData>;
type HelpCenterSelectItem = { text: string; value: string };
type SearchParams = {
  query: string;
  conversationId?: number;
  helpCenterId?: string;
  listed?: boolean;
};

const ALL = 'all';
const UNLISTED = 'unlisted';

interface Signature {
  Args: CommandKPaneComponentArgs<InsertArticleAction>;
}

export default class InsertArticlePaneComponent extends Component<Signature> {
  @service declare articlesApi: ArticlesApi;
  @service declare commandK: CommandKService;
  @service declare inboxState: InboxState;
  @service declare intl: IntlService;
  @service declare session: Session;
  @tracked query = '';
  @tracked helpCenterId: string = ALL;

  selection = useResource(this, NavigableSelection, () => ({
    items: this.items.map(({ actionItem }) => actionItem),
    isCommandKVisible: this.commandK.isVisible,
    equalityComparatorFn: (a: any, b: any) => {
      return a?.id === b?.id && a?.trackingData?.help_center_id === b?.trackingData?.help_center_id;
    },
  }));

  get items(): Array<{ actionItem: ChildAction; visibleToUser: boolean }> {
    let articles = this.articlesLoader.value?.articles ?? [];

    return this.helpCenterItems
      .filter((i) => i.value !== ALL)
      .flatMap((hcId) => {
        let isUnlisted = hcId.value === UNLISTED;

        return articles
          .filter((a: any) => {
            return isUnlisted
              ? a.help_center_ids?.length === 0 || !a.help_center_ids
              : a.help_center_ids?.includes(hcId.value);
          })
          .map((result: any) => {
            let childAction = new ChildAction({
              parent: this.args.actionItem,
              id: result.id.toString(),
              label: result.title,
              hint: result.origin,
              optionComponent: 'inbox2/command-k/insert-article/option',
              trackingData: {
                article_id: result.id,
                help_center_ids: result.help_center_ids,
                is_listed: result.help_center_ids?.length > 0,
                selected_help_center_id: this.helpCenterId,
                help_center_id: hcId.value,
              },
            });
            return { actionItem: childAction, visibleToUser: result.visible_to_user };
          });
      });
  }

  get helpCenterItems(): Array<HelpCenterSelectItem> {
    let items: Array<HelpCenterSelectItem> = [];

    if (this.helpCentersLoader.value) {
      items.push({
        text: this.intl.t('inbox.command-k.insert-article.help-center-selector.all'),
        value: ALL,
      });
      this.helpCentersLoader.value.forEach((helpCenter: AugmentedHelpCenterData) =>
        items.push({ text: helpCenter.name, value: helpCenter.id }),
      );
      items.push({
        text: this.intl.t('inbox.command-k.insert-article.help-center-selector.unlisted'),
        value: UNLISTED,
      });
    }

    return items;
  }

  get helpCenterListItems(): Array<HelpCenterSelectItem> {
    if (this.helpCenterId === ALL || !this.showHelpCenterSelector) {
      return this.helpCenterItems;
    }

    return this.helpCenterItems.filter((item) => item.value === this.helpCenterId);
  }

  get shouldShowListHeadings() {
    return this.helpCenterListItems.length > 1;
  }

  get showHelpCenterSelector(): boolean {
    return (
      this.session.workspace.isFeatureEnabled('psg-multi-help-center-ember') &&
      this.helpCentersLoader.value?.length > 1
    );
  }

  get activeConversationHelpCenterId(): string {
    return this.inboxState.activeConversationHelpCenterId ?? ALL;
  }

  get isLoadingArticles(): boolean {
    return this.articlesLoader.isLoading;
  }

  private helpCentersLoader = useFunction(
    this,
    async (previous: HelpCentersLoaderResponse): Promise<HelpCentersLoaderResponse> => {
      if (previous && previous.length > 0) {
        return previous;
      }
      let helpCenters = await this.articlesApi.helpCenters();
      this.helpCenterId = this.activeConversationHelpCenterId;

      return helpCenters;
    },
    () => [],
  );

  private articlesLoader = trackedFunction(this, async () => {
    let helpCenterId = this.helpCenterId;
    let query = this.query;

    let searchParams: SearchParams = {
      query,
      conversationId: this.inboxState.activeConversationId,
    };

    if (helpCenterId !== ALL && helpCenterId !== UNLISTED) {
      searchParams.helpCenterId = helpCenterId;
    } else if (helpCenterId === UNLISTED) {
      searchParams.listed = false;
    }
    return await this.articlesApi.inserterSearch(searchParams);
  });

  @action search(query: string) {
    this.query = query;

    this.commandK.trackSearch({
      query: this.query,
      number_of_results: this.items.length,
    });
  }

  @action onSelect(item: { actionItem: ChildAction; visibleToUser: boolean }) {
    if (item.visibleToUser) {
      this.args.onSelect(item.actionItem);
    }
  }

  @action onHelpCenterSelect(helpCenterId: string) {
    this.helpCenterId = helpCenterId;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::InsertArticle::Pane': typeof InsertArticlePaneComponent;
    'inbox2/command-k/insert-article/pane': typeof InsertArticlePaneComponent;
  }
}
