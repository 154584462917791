/* import __COLOCATED_TEMPLATE__ from './limit-hit.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-ai */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type RouterService from '@ember/routing/router-service';

interface LimitHitArgs {
  billingAdmin: boolean;
  openRequestToUpgradeModal: () => void;
}

interface Signature {
  Element: Element;
  Args: LimitHitArgs;
  Blocks: {
    default: [];
  };
}

export default class LimitHit extends Component<Signature> {
  @service declare router: RouterService;
  @service declare session: Session;
  @service declare intercomEventService: $TSFixMe;

  @action
  onUpgradeNowClick() {
    this.intercomEventService.trackEvent('clicked_fin_copilot_upgrade_button');
    this.router.transitionTo('apps.app.settings.teammates', this.session.workspace.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Freemium::LimitHit': typeof LimitHit;
    'inbox2/copilot/freemium/LimitHit': typeof LimitHit;
  }
}
