/* import __COLOCATED_TEMPLATE__ from './source-content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-ai */
import Component from '@glimmer/component';
import {
  type CopilotInteractionMetadata,
  type NormalizedFinSource,
} from 'embercom/lib/inbox2/copilot/types';
import { EntityType } from 'embercom/models/data/entity-types';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';

export interface SourceContentArgs {
  source: NormalizedFinSource;
  entityName: string;
  interactionMetadata: CopilotInteractionMetadata;
  insertBlocks?: () => void;
  iconClassNames: string;
}

interface Signature {
  Element: Element;
  Args: SourceContentArgs;
  Blocks: {
    default: [];
  };
}

export default class SourceContent extends Component<Signature> {
  @service declare session: Session;
  get isWebpage() {
    return this.args.source?.entity_type === EntityType.ExternalContent;
  }

  get isArticle() {
    return this.args.source.entity_type === EntityType.ArticleContent;
  }

  get isMacro() {
    return this.args.source.entity_type === EntityType.SavedReply;
  }

  get isPublicSource() {
    return this.isArticle || this.isWebpage;
  }

  get entityName() {
    return objectNames[this.args.source.entity_type];
  }

  get sourceUrl() {
    return this.args.source.data.source_url;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Sources::SourceComponents::SourceContent': typeof SourceContent;
    'inbox2/copilot/sources/source-components/source-content': typeof SourceContent;
  }
}
