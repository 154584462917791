/* import __COLOCATED_TEMPLATE__ from './tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-ai */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type InboxState from 'embercom/services/inbox-state';
import { type StrictModifiers } from '@popperjs/core';
import type Conversation from 'embercom/objects/inbox/conversation';
import type CopilotApi from 'embercom/services/copilot-api';

interface Args {
  highlightedUserContentText: string;
  highlightedUserContentBoundingRect: DOMRect | undefined;
  resetHighlightedUserContents: () => void;
  conversationId: Conversation['id'];
}

interface Signature {
  Args: Args;
}

export default class AskFinCopilotTooltip extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare intercomEventService: any;
  @service declare copilotApi: CopilotApi;

  get virtualElement(): HTMLElement | undefined {
    if (!this.args.highlightedUserContentText || !this.args.highlightedUserContentBoundingRect) {
      return;
    }
    return {
      getBoundingClientRect: () => this.args.highlightedUserContentBoundingRect,
    } as HTMLElement;
  }

  get askCopilotScrollModifier(): StrictModifiers {
    return {
      name: 'eventListeners',
      options: {
        scroll: false,
      },
    };
  }

  @action openCopilot() {
    this.copilotApi.triggerAndSearchCopilotQuery(this.args.highlightedUserContentText);
    this.trackAskOpenCopilotEvent();
    this.args.resetHighlightedUserContents();
    document.getSelection()?.empty();
  }

  private trackAskOpenCopilotEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'ask_copilot_clicked',
      object: 'conversation_part',
      place: 'inbox',
      section: 'conversation-stream',
      inbox_type: this.inboxState.activeInbox?.type,
      selected_text: this.args.highlightedUserContentText,
      conversation_id: this.args.conversationId,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Entry::Tooltip': typeof AskFinCopilotTooltip;
    'inbox2/copilot/entry/tooltip': typeof AskFinCopilotTooltip;
  }
}
