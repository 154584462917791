/* import __COLOCATED_TEMPLATE__ from './fin-sad-icon.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-ai */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  size: number;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

const FinSadIcon = templateOnlyComponent<Signature>();
export default FinSadIcon;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Freemium::FinSadIcon': typeof FinSadIcon;
    'inbox2/copilot/freemium/fin-sad-icon': typeof FinSadIcon;
  }
}
