/* import __COLOCATED_TEMPLATE__ from './loading.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-ai */
import templateOnlyComponent from '@ember/component/template-only';
import { type RelatedContentArgs } from '../related-content';
import type RelatedContent from '../related-content';
import { type WithBoundArgs } from '@glint/template';
export interface LoadingArgs {
  relatedContentComponent: WithBoundArgs<typeof RelatedContent, keyof RelatedContentArgs>;
}

interface Signature {
  Element: Element;
  Args: LoadingArgs;
  Blocks: {
    default: [];
  };
}

const Loading = templateOnlyComponent<Signature>();
export default Loading;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::MessageStates::Loading': typeof Loading;
    'inbox2/copilot/message-states/loading': typeof Loading;
  }
}
