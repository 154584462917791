/* import __COLOCATED_TEMPLATE__ from './preview-source-opener.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-ai */
import templateOnlyComponent from '@ember/component/template-only';
import { type NormalizedFinSource } from 'embercom/lib/inbox2/copilot/types';

export interface PreviewSourceOpenerArgs {
  source: NormalizedFinSource;
  iconClassNames: string;
  isHighlightedPreviewSource?: boolean;
}

interface Signature {
  Element: Element;
  Args: PreviewSourceOpenerArgs;
  Blocks: {
    default: [];
  };
}

const PreviewSourceOpener = templateOnlyComponent<Signature>();
export default PreviewSourceOpener;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Sources::SourceComponents::PreviewSourceOpener': typeof PreviewSourceOpener;
    'inbox2/copilot/source/source-components/preview-source-opener': typeof PreviewSourceOpener;
  }
}
