/* import __COLOCATED_TEMPLATE__ from './snooze-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { SNOOZED_UNTIL_MAP } from 'embercom/lib/inbox/constants';
import { action } from '@ember/object';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { formatTime } from 'embercom/objects/inbox/snooze';
import { calculateUnsnoozeTime, getDurationType } from 'embercom/objects/inbox/duration';
import { type MacroActionComponentArgs } from 'embercom/lib/inbox2/bulk-action-types';
import type Session from 'embercom/services/session';
import type LogService from 'embercom/services/log-service';

interface Signature {
  Args: MacroActionComponentArgs;
  Element: HTMLElement;
}

export default class SnoozeConversation extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare logService: LogService;
  @service declare session: Session;

  get label() {
    let option = SNOOZED_UNTIL_MAP.find(
      (option) => option.key === this.args.macroAction.data?.snoozed_until,
    );

    if (!option) {
      return this.intl.t('inbox.composer.macro-actions.snooze.missing-snooze-until');
    }

    return option.value;
  }

  get items() {
    return SNOOZED_UNTIL_MAP.map((snoozeOption) => {
      let durationType = getDurationType(snoozeOption.key);
      let unsnoozeTime = calculateUnsnoozeTime(durationType);
      return {
        text: this.intl.t(`inbox.command-k.snooze.${snoozeOption.intlKey}`),
        value: snoozeOption.key,
        showMetaLabel: this.showCalculatedTimes,
        metaLabel: formatTime(this.intl, unsnoozeTime),
        component: 'inbox2/composer/macro-actions/snooze/snooze-item',
      };
    });
  }

  get showCalculatedTimes() {
    return (
      this.args.actionEditorContext === 'conversation' ||
      this.args.actionEditorContext === 'bulk-edit'
    );
  }

  get isInvalid() {
    if (this.args.forceInvalid) {
      return true;
    }

    return this.args.macroAction.isInvalid;
  }

  @action onSelectItem(value: string) {
    this.args.macroAction.updateData({
      snoozed_until: value,
      author_timezone: moment.tz.guess(),
    });

    this.logService.logJSON({
      log_type: 'inboxComposerMacroActionSnoozeConversation',
      app_id: this.session.workspace.id,
      admin_id: this.session.teammate.id,
      snoozed_until: value,
      moment_cache_js_author_timezone: moment.tz.guess(),
      moment_no_cache_js_author_timezone: moment.tz.guess(true),
      browser_author_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::MacroActions::SnoozeConversation': typeof SnoozeConversation;
    'inbox2/composer/macro-actions/snooze-conversation': typeof SnoozeConversation;
  }
}
