/* import __COLOCATED_TEMPLATE__ from './call-modal.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
/* eslint-disable ember/no-empty-glimmer-component-classes */
import Component from '@glimmer/component';

import type UserSummary from 'embercom/objects/inbox/user-summary';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Session from 'embercom/services/session';
import { type TaskGenerator } from 'ember-concurrency';
import type IntlService from 'embercom/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import { action } from '@ember/object';
import type IntercomCallService from 'embercom/services/intercom-call-service';
import type PhoneNumber from 'embercom/models/inbox/phone-number';
import type Conversation from 'embercom/objects/inbox/conversation';

interface Args {
  user: UserSummary | undefined;
  isMicrophoneOn: boolean;
  isRecordingEnabled: boolean;
  renderOnHold: boolean;
  renderTransfer: boolean;
  isTimerVisible: boolean;
  isOnHold: boolean;
  isRecording: boolean;
  workspacePhoneNumber: string | undefined;
  calledNumberCountryCode: string | undefined;
  isCallRinging?: boolean;
  isCallEnded?: boolean;
  startTime?: number;
  conversation?: Conversation;
  endCall: () => void;
  pressDigit: (digit: string) => void;
  toggleMicrophone: () => void;
  toggleOnHold: () => void;
  transferTask: (newAssigneeId: number) => TaskGenerator<void>;
  transferToExternalNumberTask?: (phoneNumber: string) => TaskGenerator<void>;
  toggleRecording: () => void;
}

export default class CallModal extends Component<Args> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare intercomCallService: IntercomCallService;
  @service declare intercomEventService: any;
  @tracked usingKeypad = false;
  @tracked keypadInput = '';
  @tracked workspacePhoneNumber?: PhoneNumber;
  @tracked isCollapsed = false;
  @tracked isInvisible = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.getWorkspacePhoneNumber();
  }

  async getWorkspacePhoneNumber() {
    if (this.args.workspacePhoneNumber) {
      this.workspacePhoneNumber = this.intercomCallService.workspacePhoneNumbers.findBy(
        'phoneNumber',
        this.args.workspacePhoneNumber.replace(/ /g, ''),
      );
    }
  }

  get isTransferTaskRunning() {
    return (
      taskFor(this.args.transferTask).isRunning ||
      (this.args.transferToExternalNumberTask &&
        taskFor(this.args.transferToExternalNumberTask).isRunning)
    );
  }

  get currentPhoneNumberTitle() {
    return this.intl.t('calling.modal.current-phone-number', {
      number: this.args.workspacePhoneNumber,
      country: this.args.calledNumberCountryCode,
    });
  }

  @action pressDigit(digit: string) {
    this.keypadInput += digit;
    this.args.pressDigit(digit);
  }

  get selectedPhoneNumberText() {
    return this.workspacePhoneNumber?.name || this.workspacePhoneNumber?.phoneNumber;
  }

  get userName() {
    return (
      this.args.user?.name || this.args.user?.phone || this.intl.t('calling.modal.unknown-number')
    );
  }

  @action showKeypad() {
    this.usingKeypad = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'keypad',
      place: 'calling',
    });
  }

  @action
  async showTransferOptions() {
    this.isInvisible = true;
    this.intercomCallService.openDialler(
      undefined,
      this.returnToCall.bind(this),
      this.args.transferTask,
      this.args.transferToExternalNumberTask,
    );
  }

  @action returnToCall() {
    this.isInvisible = false;
  }

  willDestroy() {
    super.willDestroy();
    this.intercomCallService.closeDialler(true);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CallModal': typeof CallModal;
    'inbox2/call-modal': typeof CallModal;
  }
}
