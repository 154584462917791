/* import __COLOCATED_TEMPLATE__ from './phone-dialler-contact-list.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature<T extends { id: string | number }> {
  Element: HTMLElement;
  Args: {
    header: string;
    isLoading: boolean;
    hasSearched: boolean;
    noMatchesText?: string;
    emptyText?: string;
    list: T[];
    actionTooltipText: string;
    actionTooltipDisabled?: boolean;
    actionIcon: InterfaceIconName;
    actionProcessingId?: number | string;
    onClick: (item: T) => void | Promise<void>;
  };
  Blocks: {
    icon: [T];
    text: [T];
    additionInfo: [T];
  };
}

export default class PhoneDiallerContactList<T extends { id: string | number }> extends Component<
  Signature<T>
> {
  get isProcessing() {
    return !!this.args.actionProcessingId;
  }

  get showEmptyState() {
    return !this.args.list?.length;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Calling::PhoneDiallerContactList': typeof PhoneDiallerContactList;
    'inbox2/calling/phone-dialler-contact-list': typeof PhoneDiallerContactList;
  }
}
