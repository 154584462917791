/* import __COLOCATED_TEMPLATE__ from './outbound-fields.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import {
  type ReplyChannel,
  replyChannelIsEmail,
  replyChannelIsWhatsapp,
} from 'embercom/objects/inbox/composer-pane';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type UserSummary from 'embercom/objects/inbox/user-summary';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
import { type MessageSender } from 'embercom/objects/inbox/conversation';
import { tracked } from '@glimmer/tracking';

interface Args {
  channel: ReplyChannel;
  sender: MessageSender | undefined;
  onSenderChange?: (sender: any) => void;
  setRecipientErrors?: (
    recipientsWithErrors: { recipient: UserSummary; recipientError: string | void }[],
  ) => void;
  recipients?: UserSummary[];
  replyChannel: ReplyChannel;
  onRecipientChange: (recipients?: UserSummary[]) => void;
  title?: string;
  setTitle?: Function;
  isCreatingConversation?: boolean;
  keyboardPriority?: number;
  isSideConversationComposer?: boolean;
}

interface Signature {
  Args: Args;
}

export default class OutboundFields extends Component<Signature> {
  @service declare session: Session;
  readonly replyChannelIsEmail = replyChannelIsEmail;
  readonly replyChannelIsWhatsapp = replyChannelIsWhatsapp;
  @tracked customAddressExists = false;

  @ref('subject-input') declare subjectInput: HTMLElement;

  @action selectSubjectField() {
    this.subjectInput?.focus();
  }

  get shouldAddRecipientDataAttribute(): boolean {
    return this.replyChannelIsEmail(this.args.replyChannel);
  }

  get channelIsWhatsapp(): boolean {
    return this.replyChannelIsWhatsapp(this.args.replyChannel);
  }

  @action
  setCustomAddressExists(customAddressExists: boolean) {
    this.customAddressExists = customAddressExists && !!this.args.isSideConversationComposer;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Composer::OutboundFields': typeof OutboundFields;
    'inbox2/composer/outbound-fields': typeof OutboundFields;
  }
}
