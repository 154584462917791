/* import __COLOCATED_TEMPLATE__ from './conversation-panel.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import { action } from '@ember/object';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
import { inject as service } from '@ember/service';
import type InboxState from 'embercom/services/inbox-state';
import { ComposerLocation } from 'embercom/services/inbox-state';
import { ConversationResource } from './conversation-resource';
import type Conversation from 'embercom/objects/inbox/conversation';
import type Session from 'embercom/services/session';
import type ConversationStreamSettings from 'embercom/services/conversation-stream-settings';
import { ComposerPaneType } from 'embercom/objects/inbox/composer-pane';
import { tracked } from '@glimmer/tracking';
import SmartReplyResource from 'embercom/components/inbox2/smart-reply-resource';
import { next } from '@ember/runloop';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import { type DurationType } from 'embercom/objects/inbox/duration';
import type CommandKService from 'embercom/services/command-k';
import FormForDownload from 'embercom/lib/form-for-download';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import type IntlService from 'embercom/services/intl';
import type Router from '@ember/routing/router-service';
import { type SendAndCloseFn } from 'embercom/objects/inbox/types/composer';
import type AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import {
  canExportConversationTranscriptsInbox2,
  exportConversationTranscriptsPermission,
} from 'embercom/lib/settings/permissions/inline-permission-enforcement';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import { type NexusEvent } from 'embercom/services/nexus';
import type Nexus from 'embercom/services/nexus';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { type EmberKeyboardEvent, EscKeyPriority } from 'embercom/lib/inbox2/types';
// @ts-ignore
import intermoji from '@intercom/intermoji';
import type EmojiService from 'embercom/services/emoji-service';
import type LbaMetricsService from 'embercom/services/lba-metrics-service';
import { LbaTriggerEvent } from 'embercom/services/lba-metrics-service';

interface Args<T> {
  conversationId: number;
  conversationTableEntry: ConversationTableEntry;
  shouldFocusComposer?: boolean;
  resetShouldFocusComposer?: () => void;
  isNotePaneActive: boolean;
  onSendAndClose?: SendAndCloseFn;
  togglePriority: (conversation: T, priority: boolean) => unknown;
  snoozeConversation: (conversation: T, duration: DurationType) => unknown;
  openConversation: (conversation: T) => unknown;
  selectedTab?: string;
  onAdminUpdate: (conversation: ConversationTableEntry, admin: AdminWithPermissions) => unknown;
  onTeamUpdate: (conversation: ConversationTableEntry, team: TeamSummary) => unknown;
  onClose?: () => void;
  isFullConversationPanel?: boolean;
}

interface Signature<T> {
  Args: Args<T>;
}

type FocusFunction = () => void;
export default class ConversationPanel extends Component<
  Signature<Conversation | ConversationTableEntry>
> {
  @service declare session: Session;
  @service declare inboxState: InboxState;
  @service declare conversationStreamSettings: ConversationStreamSettings;
  @service declare commandK: CommandKService;
  @service declare intercomEventService: any;
  @service declare permissionsService: any;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare nexus: Nexus;
  @service declare emojiService: EmojiService;
  @service declare lbaMetricsService: LbaMetricsService;

  @tracked composerFocusFn?: FocusFunction;
  @tracked canShowSendAndCloseHint = false;

  @trackedReset({
    memo: 'conversationResource.conversation.id',
    update() {
      return this.defaultTabName();
    },
  })
  selectedTab = this.defaultTabName();

  @trackedReset({
    memo: 'args.shouldFocusComposer',
    update(component: ConversationPanel, _: string, last: boolean) {
      // When shouldFocusComposer changes, we'll recalculate the composer
      // visibility.

      // However, if the composer was already visible, we don't change its
      // visibility. If it wasn't visible and shouldFocusComposer became true,
      // then we make it visible.
      if (last) {
        return last;
      }

      return component.args.shouldFocusComposer;
    },
  })
  isComposerVisible = this.args.shouldFocusComposer ?? false;

  @ref('preview-panel-composer-container') declare composerContainer: HTMLElement;
  @ref('preview-panel-composer-opener') declare composerOpener: HTMLElement;

  readonly notePane = ComposerPaneType.Note;
  readonly replyPane = ComposerPaneType.Reply;
  readonly ComposerLocation = ComposerLocation;
  readonly defaultInbox = { id: 'all', category: 'shared' };

  // We need to set a higher keyboard priority for the command-k actions within
  // the preview panel so that command-k targets the conversation in the preview
  // panel instead of the active conversation.
  readonly keyboardPriority = 1;

  conversationResource = ConversationResource.from(this, () => ({
    conversationId: this.args.conversationId,
  }));

  smartReplyResource = SmartReplyResource.from(this, () => ({
    conversation: this.conversationResource.conversation,
  }));

  @action
  handleThreadUpdatedEvent(e: NexusEvent) {
    if (Number(e.eventData.conversationId) === this.args.conversationId) {
      this.reloadConversation();
    }
  }

  defaultTabName() {
    if (this.args.selectedTab) {
      return this.args.selectedTab;
    } else {
      return 'activity';
    }

    return this.restyledTicketPreviewEnabled && this.conversation.isTicket ? 'details' : 'stream';
  }

  get conversation(): Conversation {
    return this.conversationResource.conversation;
  }

  get conversationToAction(): ConversationTableEntry | Conversation {
    return this.args.conversationTableEntry ? this.args.conversationTableEntry : this.conversation;
  }

  get hasError(): boolean {
    return this.conversationResource.hasError;
  }

  get activePane(): ComposerPaneType {
    if (this.forceNotePane) {
      return this.notePane;
    }

    if (this.args.isNotePaneActive !== undefined) {
      return this.args.isNotePaneActive ? this.notePane : this.replyPane;
    }

    return this.defaultToNotePane ? this.notePane : this.replyPane;
  }

  get defaultToNotePane() {
    return (
      !this.conversation.isLoading &&
      (this.conversation.commentOrNoteParts.length === 0 || this.conversation.lastCommentIsANote) &&
      this.conversation.ticketType !== undefined
    );
  }

  get forceNotePane() {
    return this.session.showLightInbox || !this.conversation.isReplyable;
  }

  get isTicketNotesComposer() {
    return this.conversation.isTicket && !this.conversation.isReplyable;
  }

  get showParentTicket() {
    return this.conversation.isChild;
  }

  get parentTicketName() {
    return this.conversation.parent?.ticketType?.name || this.intl.t('inbox.conversation');
  }

  get parentUserName() {
    return this.conversation.parent?.userSummary?.displayAs;
  }

  get ticketTypeEmoji() {
    return intermoji.twemojiSVGUri(this.conversation.ticketType?.emoji ?? '');
  }

  get escPriority() {
    return EscKeyPriority.conversationPanel;
  }

  get restyledTicketPreviewEnabled() {
    return this.session.workspace.isFeatureEnabled('tickets-2-linked-tickets-open-preview');
  }

  get tabDetails() {
    let detailsTab = { value: 'details', translationKey: 'details' };
    let activityTab = { value: 'activity', translationKey: 'activity' };

    return [activityTab, detailsTab];
  }

  get showTicketStream() {
    return (
      !this.conversation.isReplyable &&
      this.session.workspace.isFeatureEnabled('ticket-stream-restyling')
    );
  }

  get isPreviewingTicket() {
    return this.inboxSidebarService.isPreviewingLinkedItem && this.conversation.isTicket;
  }

  get multistateIconOnly() {
    return this.isNarrowSidebarPreview || this.conversation.isSnoozed;
  }

  get isNarrowSidebarPreview() {
    if (!this.inboxSidebarService.isPreviewingLinkedItem) {
      return false;
    } else if (this.args.isFullConversationPanel && this.inboxState.isNarrowConversationStream) {
      return true;
    }

    return this.inboxState.sidebarWidthInPixels <= 450;
  }

  get ticketCategory() {
    return this.conversation.ticketCategory;
  }

  @action onReady({ focus }: { focus: FocusFunction }) {
    this.composerFocusFn = focus;

    if (this.args.shouldFocusComposer) {
      next(() => focus());
      this.args.resetShouldFocusComposer?.();
    }
  }

  sendAndClose: SendAndCloseFn = async (
    blocks,
    macroActions,
    _,
    replyData,
    participantData,
    emailHistoryMetadataId?: number,
  ) => {
    if (this.args.onSendAndClose) {
      this.args.onSendAndClose(
        blocks,
        macroActions,
        undefined,
        replyData,
        participantData,
        emailHistoryMetadataId,
      );
    } else {
      let isRequiredAttributePanelShown = await this.inboxState.maybeShowRequiredAttributesPanel(
        this.conversationToAction,
      );
      // Panel is already shown, so send the reply
      if (isRequiredAttributePanelShown) {
        this.conversationResource.sendReplyOrNote(
          this.activePane,
          blocks,
          macroActions,
          undefined,
          replyData,
          participantData,
          false,
          emailHistoryMetadataId,
        );
      } else {
        this.conversationResource.sendAndClose(
          blocks,
          macroActions,
          undefined,
          replyData,
          participantData,
          emailHistoryMetadataId,
        );
      }
    }
  };

  @action showComposer() {
    this.isComposerVisible = true;
    this.toggleMessengerVisibility({ hide: true });
    this.inboxState.setActiveComposer(ComposerLocation.ConversationPreviewPanel);
    next(() => this.composerFocusFn?.());
  }

  @action hideComposer(event?: Event, kev?: EmberKeyboardEvent) {
    event?.stopPropagation();
    kev?.stopPropagation();
    kev?.stopImmediatePropagation();

    this.isComposerVisible = false;
    this.toggleMessengerVisibility({ hide: false });
    this.inboxState.setActiveComposer(ComposerLocation.ConversationPage);
    setTimeout(() => this.composerOpener.focus(), 20);
  }

  @action handleDocumentClick(event: PointerEvent) {
    if (!this.isComposerVisible || !event.target) {
      return;
    }

    let ignoredElements = [
      this.composerContainer,
      document.querySelector('.popover__content'),
      document.querySelector('.inbox2__command-k-modal'),
      document.querySelector('.prosemirror-composer-popover-container'),
      document.querySelector('.inbox-2__resolve-and-close-modal'),
    ].compact() as HTMLElement[];

    let ignoreClick = ignoredElements.any((element) =>
      element.contains(event.target as HTMLElement),
    );
    if (ignoreClick) {
      return;
    }

    this.hideComposer();
  }

  private toggleMessengerVisibility({ hide }: { hide: boolean }) {
    if ('Intercom' in window) {
      window.Intercom('update', {
        hide_default_launcher: hide,
      });
    }
  }

  @action reloadConversation() {
    this.conversationResource.reload();
  }

  @action togglePriority() {
    this.args.togglePriority(this.conversationToAction, !this.conversationToAction.priority);
  }

  @action snooze() {
    this.commandK.registerAndShow({
      actionID: 'snooze',
      onSelect: (duration: DurationType) => {
        this.args.snoozeConversation(this.conversationToAction, duration);
        this.trackSnooze(duration);
      },
    });
  }

  private trackSnooze(duration: DurationType) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'snoozed',
      section: 'conversation_preview',
      object: 'conversation',
      layout_type: this.inboxState.activeConversationsView,
      snoozed_until: duration,
    });

    this.lbaMetricsService.trackTeammateMaybeWaitingForNewConversationAt(LbaTriggerEvent.SNOOZE);
  }

  @action async unsnooze() {
    await this.args.openConversation(this.conversationToAction);
  }

  @action onToggleConversationEvents() {
    this.selectedTab = 'stream';
    this.conversationStreamSettings.toggleEvents();
  }

  get hideEvents() {
    return this.conversationStreamSettings.hideEvents;
  }

  @action exportConversation() {
    if (!canExportConversationTranscriptsInbox2(this.session)) {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        exportConversationTranscriptsPermission,
      );
    }
    let url = `ember/conversations/download_transcript?v=${Math.floor(Math.random() * 1e8)}`;
    FormForDownload.postNewTab(url, {
      app_id: this.session.workspace.id,
      conversation_id: this.args.conversationId,
    });
  }

  @action trackSidebarToggle(options = { keyboardShortcutUsed: false }) {
    this.intercomEventService.trackAnalyticsEvent({
      action: this.inboxSidebarService.isSidebarCollapsed ? 'hidden' : 'shown',
      object: 'conversation_panel',
      section: options.keyboardShortcutUsed ? 'shortcuts' : undefined,
      shortcut_key: options.keyboardShortcutUsed,
    });
  }

  @action goToParent() {
    let parentConversationId = this.conversation.parent?.id;
    if (parentConversationId) {
      this.router.transitionTo(this.router.currentRouteName, parentConversationId);
    }
  }

  @action onAdminUpdate(admin: AdminWithPermissions) {
    this.args.onAdminUpdate(this.args.conversationTableEntry, admin);
  }

  @action onTeamUpdate(team: TeamSummary) {
    this.args.onTeamUpdate(this.args.conversationTableEntry, team);
  }

  @action async closeConversation(record: Conversation | ConversationTableEntry) {
    await this.inboxState.closeConversationAndCheckAttributes(record);
    let { conversation } = this.conversationResource;

    let closedWithin5Seconds =
      conversation.isLastPart(RenderableType.AdminComment) &&
      conversation.isLastPartCreatedWithin({ seconds: 5 });

    if (closedWithin5Seconds) {
      this.canShowSendAndCloseHint = true;
    }

    this.trackCloseConversation({ closedWithin5Seconds });
  }

  @action dismissSendAndCloseHint() {
    this.canShowSendAndCloseHint = false;
  }

  private trackCloseConversation(metadata: { closedWithin5Seconds?: boolean } = {}) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'closed',
      section: 'conversation_panel',
      object: 'conversation',
      closed_within_5_seconds_of_reply: metadata.closedWithin5Seconds ?? false,
    });

    this.lbaMetricsService.trackTeammateMaybeWaitingForNewConversationAt(LbaTriggerEvent.CLOSE);
  }

  @action onClose(event?: Event, kev?: EmberKeyboardEvent) {
    event?.stopPropagation();
    kev?.stopPropagation();
    kev?.stopImmediatePropagation();

    this.args.onClose?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationPanel': typeof ConversationPanel;
    'inbox2/conversation-panel': typeof ConversationPanel;
  }
}
