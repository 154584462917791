/* import __COLOCATED_TEMPLATE__ from './select.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const Select = templateOnlyComponent<Signature>();
export default Select;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::Attribute::Editor::Select': typeof Select;
    'inbox2/conversation-details-sidebar/attribute/editor/select': typeof Select;
  }
}
