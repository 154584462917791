/* import __COLOCATED_TEMPLATE__ from './load-related-content.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-ai */
import templateOnlyComponent from '@ember/component/template-only';
import { type RelatedContentArgs as RelatedContentComponentArgs } from '../related-content';
import type RelatedContentComponent from '../related-content';
import { type WithBoundArgs } from '@glint/template';
import { type InboxAssistantConversationPart } from 'embercom/objects/inbox/inbox-assistant-conversation';

export interface LoadRelatedContentArgs {
  message: InboxAssistantConversationPart;
  relatedContentComponent: WithBoundArgs<
    typeof RelatedContentComponent,
    keyof RelatedContentComponentArgs
  >;
}

interface Signature {
  Element: Element;
  Args: LoadRelatedContentArgs;
  Blocks: {
    default: [];
  };
}

const LoadRelatedContent = templateOnlyComponent<Signature>();
export default LoadRelatedContent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::MessageStates::LoadRelatedContent': typeof LoadRelatedContent;
    'inbox2/copilot/message-states/load-related-content': typeof LoadRelatedContent;
  }
}
