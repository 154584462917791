/* import __COLOCATED_TEMPLATE__ from './macro.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-ai */

import Component from '@glimmer/component';
import type Macro from 'embercom/objects/inbox/macro';
import type Inbox2MacrosSearch from 'embercom/services/inbox2-macros-search';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { action } from '@ember/object';
import { EntityType } from 'embercom/models/data/entity-types';
import { type CopilotChatMessageAnalyticsData } from 'embercom/lib/inbox2/copilot/types';
import { type BlockList } from '@intercom/interblocks.ts';
import { type MacroAction } from 'embercom/objects/inbox/macro';
import type Conversation from 'embercom/objects/inbox/conversation';
import type SavedReplyInsertionsService from 'embercom/services/saved-reply-insertions-service';

type InsertBlocksFn = (
  blocks: BlockList,
  conversationId?: Conversation['id'],
  actions?: MacroAction[],
) => void;

export interface MacroPreviewArgs {
  entityId: Macro['id'];
  onClose: () => void;
  analyticsData: CopilotChatMessageAnalyticsData;
  conversation: Conversation;
  onAddToComposerClick: InsertBlocksFn;
  onAddNoteToComposerClick: InsertBlocksFn;
}

interface Signature {
  Args: MacroPreviewArgs;
}

export default class MacroPreview extends Component<Signature> {
  @service declare inbox2MacrosSearch: Inbox2MacrosSearch;
  @service declare savedReplyInsertionsService: SavedReplyInsertionsService;

  @service declare session: Session;
  @service declare intercomEventService: any;

  get macro() {
    return this.inbox2MacrosSearch.findById(this.args.entityId);
  }

  get hasAttachments() {
    return this.macro?.blocks.some((block) => block.type === 'attachmentList');
  }

  @action
  async applyMacro(macro: Macro, insertBlocksFn: InsertBlocksFn) {
    let renderedMacro = await macro.render(
      this.session.workspace.id,
      this.args.conversation.participantSummaries.length > 1,
      this.args.conversation.id.toString(),
      this.args.conversation.participantSummaries.get(0)?.id?.toString(),
    );

    insertBlocksFn(renderedMacro.blocks, this.args.conversation.id, renderedMacro.actions);

    this.intercomEventService.trackAnalyticsEvent({
      object: 'macro',
      action: 'inserted',
      context: 'related_content',
      ...this.args.analyticsData,
      entity_id: this.args.entityId,
      entity_type: EntityType.SavedReply,
    });

    await this.savedReplyInsertionsService.recordInsertion(
      this.session.workspace,
      this.session.teammate,
      macro,
      this.args.conversation.id,
    );
  }
  @action instrumentCopy() {
    let selection = document.getSelection()?.toString();
    // The 'copy' event seems to get triggred if you select, then unselect, then copy
    // So we need to ignore these events if there isn't any text currently selected
    if (selection) {
      this.intercomEventService.trackAnalyticsEvent({
        object: 'related_content_sheet',
        action: 'manually_copied',
        context: 'related_content',
        ...this.args.analyticsData,
        entity_id: this.args.entityId,
        entity_type: EntityType.SavedReply,
        text: selection,
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::SourcePreviews::Macro': typeof MacroPreview;
    'inbox2/copilot/source-previews/macro': typeof MacroPreview;
  }
}
