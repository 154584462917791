/* import __COLOCATED_TEMPLATE__ from './suggestion-carousel-button.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-ai */
import templateOnlyComponent from '@ember/component/template-only';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface SuggestionCarouselButtonArgs {
  icon: InterfaceIconName;
}

interface Signature {
  Element: Element;
  Args: SuggestionCarouselButtonArgs;
  Blocks: {
    default: [];
  };
}

const SuggestionCarouselButton = templateOnlyComponent<Signature>();
export default SuggestionCarouselButton;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Suggestions::SuggestionCarouselButton': typeof SuggestionCarouselButton;
    'inbox2/copilot/suggestions/suggestion-carousel-button': typeof SuggestionCarouselButton;
  }
}
