/* import __COLOCATED_TEMPLATE__ from './pill.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLSpanElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Pill = templateOnlyComponent<Signature>();
export default Pill;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::Pill': typeof Pill;
    'inbox2/common/pill': typeof Pill;
  }
}
