/* import __COLOCATED_TEMPLATE__ from './tags.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Conversation from 'embercom/objects/inbox/conversation';
import type SidebarSection from 'embercom/objects/inbox/sidebar-section';
import Tag, { type TagActionType, type TagMenuSource } from 'embercom/objects/inbox/tag';
import type User from 'embercom/objects/inbox/user';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import type IntlService from 'embercom/services/intl';
import type InboxApi from 'embercom/services/inbox-api';
import type UserApi from 'embercom/services/user-api';
import type RouterService from '@ember/routing/router-service';
import type Session from 'embercom/services/session';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { tracked } from '@glimmer/tracking';
import { includesTag } from 'embercom/objects/inbox/taggable-part';
import type CommandKService from 'embercom/services/command-k';
import { CommandKTagActionId } from 'embercom/objects/inbox/command-k/tag-item';
import UserTagsResource from 'embercom/resources/inbox2/user-tags';
import { type SidebarSectionComponentArgs } from 'embercom/lib/inbox2/sidebar-types';

type Args = SidebarSectionComponentArgs<{
  conversation: Conversation;
  isLoadingUser: boolean;
  sidebarSection: SidebarSection;
  allTags: Record<string, Tag>;
  loadAllTags: Function;
  user?: User;
  activeInbox?: Inbox;
  commandKActionPriority?: number;
}>;

interface Signature {
  Args: Args;
}

type SectionId = 'tags';

export default class Tags extends Component<Signature> {
  readonly sectionId: SectionId = 'tags';

  @service declare intercomEventService: any;
  @service declare inboxApi: InboxApi;
  @service declare userApi: UserApi;
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare router: RouterService;
  @service declare notificationsService: any;
  @service declare commandK: CommandKService;

  @tracked tags: Tag[] = [];
  @tracked loadInitialTags = false;
  @tracked tagMenuSource: TagMenuSource = null;
  @tracked tagActionType: TagActionType = null;

  initialFetchedTags = UserTagsResource.from(this, () => ({
    userId: this.args.user?.id,
    onLoadTagsStarted: () => {
      this.loadInitialTags = true;
    },
  }));

  get userTags(): Tag[] {
    let initialTags = this.initialFetchedTags.tags;
    let tagsToPresent = this.loadInitialTags ? initialTags : this.tags;

    return tagsToPresent;
  }

  get limitedUserTags(): Tag[] {
    return this.userTags.slice(0, 5);
  }

  get context() {
    return {
      user: this.args.user,
      tags: this.userTags,
    };
  }

  get isLead(): boolean {
    return this.args.user?.role === 'contact_role';
  }

  @action setTagActionType(type: TagActionType) {
    this.tagActionType = type;
  }

  @action async addTag() {
    if (!this.args.user) {
      return;
    }

    let actionId = this.isLead ? CommandKTagActionId.TagLead : CommandKTagActionId.TagUser;

    this.commandK.findAndShow(actionId, () => {});
  }

  @action async removeTag(tag: Tag) {
    this.setTagActionType('removed');
    await this.updateTagsForUser({
      user: this.args.user,
      updatedTags: this.userTags.filter((x) => x !== tag),
    });
  }

  private async updateTagsForUser(props: { user: User | undefined; updatedTags: Tag[] }) {
    if (!props.user) {
      return;
    }

    this.loadInitialTags = false;
    let oldTags = this.userTags;

    try {
      this.tags = props.updatedTags;
      let response = await this.inboxApi.updateTagsForUser(props.user, props.updatedTags);
      // Update the id for newly created tags
      if (response?.tags?.length && props.updatedTags.some((tag) => !tag.id)) {
        let updatedTags = props.updatedTags.map((tag) => {
          if (!tag.id) {
            let createdTag = response.tags.find((t: Tag) => t.name === tag.name);
            if (createdTag) {
              return new Tag(
                createdTag.id,
                createdTag.name,
                this.session.teammate,
                new Date(createdTag.created_at),
              );
            }
          }

          return tag;
        });
        this.tags = updatedTags;
      }
    } catch (error) {
      this.notificationsService.notifyResponseError(error);
      this.tags = oldTags;
    }
  }

  @action async tagUser(tag: Tag, context: { user: User; tags: Tag[] }) {
    let userIsTagged = includesTag(context.tags, tag);
    let updatedTags: Tag[] = [];

    if (userIsTagged) {
      updatedTags = context.tags.filter(({ id }) => id !== tag.id);
    } else {
      updatedTags = [...context.tags, new Tag(tag.id, tag.name, this.session.teammate)];
    }
    this.setTagActionType(userIsTagged ? 'removed' : 'added');
    await this.updateTagsForUser({ user: context.user, updatedTags });
  }

  @action manageTags() {
    let url: string = this.router.urlFor('apps.app.settings.tags-new', this.session.workspace);
    safeWindowOpen(url);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetailsSidebar::SidebarSections::Tags': typeof Tags;
    'inbox2/conversation-details-sidebar/sidebar-sections/tags': typeof Tags;
  }
}
