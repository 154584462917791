/* import __COLOCATED_TEMPLATE__ from './button.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';

type ButtonType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'link'
  | 'alert'
  | 'danger'
  | 'call'
  | 'activeCall'
  | 'secondaryText';

type ButtonSize = 'base' | 'sm' | 'narrow' | 'xs' | 'lg' | 'fill';

export interface Inbox2ButtonArgs {
  type: ButtonType;
  size?: ButtonSize;
  forceActive?: boolean;
  disabled?: boolean;
  iconOnly?: boolean;
  grayscale?: boolean;
}

interface Signature {
  Element: HTMLButtonElement;
  Args: Inbox2ButtonArgs;
  Blocks: {
    default: [];
  };
}

export default class Inbox2CommonButtonComponent extends Component<Signature> {
  get size() {
    return this.args.size ?? 'base';
  }

  get type() {
    return this.args.type ?? 'primary';
  }

  get sizeClasses() {
    if (this.args.type === 'link' && !this.args.iconOnly) {
      return 'px-1';
    }

    let classes = {
      base: this.args.iconOnly ? 'w-8 h-8 p-2' : 'py-2 px-3',
      sm: this.args.iconOnly ? 'p-1 inbox2__button--small' : 'py-1 px-2 inbox2__button--small',
      narrow: 'py-1 px-0 inbox2__button--small',
      xs: 'p-0 inbox2__button--xs',
      lg: this.args.iconOnly ? 'p-4 inbox2__button--large' : 'px-6 py-3 inbox2__button--large',
      fill: 'px-3 py-2 inbox2__button--fill',
    }[this.size];

    return `inbox2__button ${classes}`;
  }

  get colorClasses(): string | void {
    return {
      primary: this.primaryClasses,
      secondary: this.secondaryClasses,
      tertiary: this.tertiaryClasses,
      alert: this.alertClasses,
      link: this.linkClasses,
      danger: this.dangerClasses,
      call: this.callClasses,
      activeCall: this.activeCallClasses,
      secondaryText: this.secondaryTextClasses,
    }[this.type].trim();
  }

  private get primaryClasses(): string {
    if (this.args.disabled) {
      return `bg-gray text-gray-light pointer-events-none dark:bg-dm-gray dark:text-dm-black`;
    }

    return `
      ${
        this.args.forceActive
          ? 'bg-gray-dark text-white border-sky-dark dark:bg-dm-white dark:text-dm-black guide-library-el-active'
          : 'bg-black text-white dark:bg-dm-gray-light dark:text-dm-black'
      }
      hover:bg-gray-dark dark:hover:bg-dm-white
      active:bg-gray-dark active:border-sky-dark dark:active:bg-dm-white dark:active:border-dm-blue
      focus:bg-gray-dark focus:border-sky-dark dark:active:bg-dm-white dark:focus:border-dm-blue dark:focus:border-opacity-90
    `;
  }

  private get secondaryClasses() {
    if (this.args.disabled) {
      return `bg-gray-lightest text-gray pointer-events-none dark:bg-dm-gray-darker dark:bg-dm-text-gray`;
    }

    return `
      ${
        this.args.forceActive
          ? 'bg-sky-dark text-blue border-sky-dark dark:bg-dm-blue-dark dark:text-dm-blue dark:border-dm-blue guide-library-el-active'
          : 'bg-gray-lightest text-black dark:bg-dm-gray-darker dark:text-dm-white'
      }
      hover:bg-sky-dark hover:text-blue dark:hover:bg-dm-blue-dark dark:hover:text-dm-blue
      active:bg-sky-dark active:text-blue active:border-sky-dark dark:active:bg-dm-blue-dark dark:active:text-dm-blue dark:active:border-dm-blue
      focus:bg-sky-dark focus:text-blue focus:border-blue focus:border-opacity-25 dark:focus:bg-dm-blue-dark dark:focus:text-dm-blue dark:focus:border-dm-blue
    `;
  }

  private get secondaryTextClasses() {
    if (this.args.disabled) {
      return `text-gray pointer-events-none dark:bg-dm-gray-darker dark:bg-dm-text-gray`;
    }

    return `
      ${
        this.args.forceActive
          ? 'bg-sky-dark text-blue border-sky-dark dark:bg-dm-blue-dark dark:text-dm-blue dark:border-dm-blue guide-library-el-active'
          : 'bg-gray-lightest text-black dark:bg-dm-gray-darker dark:text-dm-white'
      }
      hover:bg-sky-dark hover:text-blue dark:hover:bg-dm-blue-dark dark:hover:text-dm-blue
      active:bg-sky-dark active:text-blue active:border-sky-dark dark:active:bg-dm-blue-dark dark:active:text-dm-blue dark:active:border-dm-blue
      focus:bg-sky-dark focus:text-blue focus:border-blue focus:border-opacity-25 dark:focus:bg-dm-blue-dark dark:focus:text-dm-blue dark:focus:border-dm-blue
    `;
  }

  private get tertiaryClasses() {
    if (this.args.disabled) {
      return `text-gray pointer-events-none dark:text-dm-gray`;
    }

    return `
      ${
        this.args.forceActive
          ? 'bg-sky-dark text-blue dark:text-dm-blue dark:bg-dm-blue-dark guide-library-el-active'
          : 'bg-transparent text-black dark:text-dm-white'
      }
      hover:bg-sky-dark hover:text-blue dark:hover:text-dm-blue dark:hover:bg-dm-blue-dark
      active:bg-sky-dark active:text-blue dark:active:text-dm-blue dark:active:bg-dm-blue-dark
      focus:bg-sky-dark focus:text-blue focus:ring-2 focus:ring-color-blue/30 dark:focus:text-dm-blue dark:focus:bg-dm-blue-dark dark:ring-color-dm-blue/30
    `;
  }

  private get linkClasses() {
    if (this.args.disabled) {
      return `text-gray pointer-events-none dark:text-dm-gray`;
    }
    let colour = this.args.grayscale
      ? 'text-gray dark:text-dm-gray'
      : 'text-black dark:text-dm-white';
    return `
      ${this.args.forceActive ? 'text-blue dark:text-dm-blue' : `bg-transparent ${colour}`}
      hover:text-blue dark:hover:text-dm-blue
      active:text-blue dark:active:text-dm-blue
      focus:text-blue focus:ring-2 focus:ring-color-blue/30 dark:focus:text-dm-blue dark:ring-color-dm-blue/30
    `;
  }

  private get alertClasses() {
    if (this.args.disabled) {
      return `bg-gray-lightest text-gray pointer-events-none dark:bg-dm-gray-darker dark:bg-dm-text-gray`;
    }

    return `
      ${
        this.args.forceActive
          ? 'bg-red-light text-red border-red border-opacity-50 dark:text-dm-red dark:bg-dm-red-dark'
          : 'bg-red-light text-red-dark dark:bg-dm-red-dark dark:text-dm-red-light'
      }
      hover:text-red dark:hover:text-dm-red
      active:text-red active:border-red active:border-opacity-50 dark:active:border-dm-red dark:active:text-dm-red
      focus:text-red focus:border-red focus:border-opacity-50 dark:focus:border-dm-red dark:focus:text-dm-red
    `;
  }

  private get dangerClasses() {
    if (this.args.disabled) {
      return `bg-gray-lightest text-gray pointer-events-none dark:bg-dm-gray-darker dark:bg-dm-text-gray`;
    }

    return `
      text-white
      ${
        this.args.forceActive
          ? 'bg-red-dark dark:text-dm-red-dark dark:bg-dm-red-light'
          : 'bg-red dark:text-dm-red-dark dark:bg-dm-red'
      }
      hover:bg-red-dark dark:hover:text-dm-red-dark dark:hover:bg-dm-red-light
      active:bg-red-dark dark:active:text-dm-red-dark dark:active:bg-dm-red-light
      focus:bg-red-dark dark:focus:text-dm-red-dark dark:focus:bg-dm-red-light
    `;
  }

  private get callClasses() {
    if (this.args.disabled) {
      return `rounded-full shadow-lg bg-black text-gray pointer-events-none dark:bg-dm-gray-darker dark:bg-dm-text-gray`;
    }

    return `
      rounded-full shadow-lg
      ${
        this.args.forceActive
          ? 'bg-white text-black dark:bg-white dark:text-black'
          : 'bg-gray bg-opacity-50 text-white dark:bg-gray dark:bg-opacity-50 dark:text-white'
      }
      hover:bg-white hover:text-black dark:hover:bg-white dark:hover:text-black
      active:bg-white active:text-black dark:active:bg-white dark:active:text-black
    `;
  }

  private get activeCallClasses() {
    if (this.args.disabled) {
      return `rounded-full shadow-lg bg-white text-gray pointer-events-none dark:bg-dm-gray-darker dark:bg-dm-text-gray`;
    }

    return `
      rounded-full shadow-lg
      ${
        this.args.forceActive
          ? 'bg-black bg-opacity-50 text-white dark:bg-black dark:bg-opacity-50 dark:text-white guide-library-el-active'
          : 'bg-white bg-opacity-100 text-black dark:bg-white dark:bg-opacity-100 dark:text-black'
      }
      hover:bg-black hover:text-white bg-opacity-50 dark:hover:bg-black dark:hover:text-white dark:bg-opacity-50
      active:bg-black active:text-white bg-opacity-50 dark:active:bg-black dark:active:text-white dark:bg-opacity-50
    `;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Common::Button': typeof Inbox2CommonButtonComponent;
  }
}
