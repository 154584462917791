/* import __COLOCATED_TEMPLATE__ from './conversation.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-ai */

import type ConversationType from 'embercom/objects/inbox/conversation';
import Component from '@glimmer/component';
import { ConversationResource } from '../../conversation-resource';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import type InboxState from 'embercom/services/inbox-state';
import type Session from 'embercom/services/session';
import { type CopilotChatMessageAnalyticsData } from 'embercom/lib/inbox2/copilot/types';
import { EntityType } from 'embercom/models/data/entity-types';

export interface ConversationArgs {
  conversationId: number;
  entityId: number;
  closeConversationPreview: () => void;
  highlightedConversationPartSelectors: string[];
  analyticsData: CopilotChatMessageAnalyticsData;
}

interface Signature {
  Element: Element;
  Args: ConversationArgs;
  Blocks: {
    default: [];
  };
}

export default class Conversation extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxState: InboxState;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare intercomEventService: any;

  conversationResource = ConversationResource.from(this, () => ({
    conversationId: this.args.conversationId,
  }));

  get conversation(): ConversationType {
    return this.conversationResource.conversation;
  }

  get highlightedConversationPartSelectors() {
    return this.args.highlightedConversationPartSelectors;
  }

  get isLoading() {
    return this.conversationResource.isLoading;
  }

  get inbox() {
    return this.inboxState.activeInbox || { id: 'all', category: 'shared' };
  }

  @action instrumentCopy() {
    let selection = document.getSelection()?.toString();
    // The 'copy' event seems to get triggred if you select, then unselect, then copy
    // So we need to ignore these events if there isn't any text currently selected
    if (selection) {
      this.intercomEventService.trackAnalyticsEvent({
        object: 'related_content_sheet',
        action: 'manually_copied',
        context: 'related_content',
        entity_id: this.args.entityId,
        entity_type: EntityType.PastConversationSnippet,
        ...this.args.analyticsData,
        text: selection,
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::SourcePreviews::Conversation': typeof Conversation;
    'inbox2/Copilot/SourcePreviews/conversation-source-preview': typeof Conversation;
  }
}
