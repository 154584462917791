/* import __COLOCATED_TEMPLATE__ from './ticket-panel.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import type InboxState from 'embercom/services/inbox-state';
import { ComposerLocation } from 'embercom/services/inbox-state';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type ConversationStreamSettings from 'embercom/services/conversation-stream-settings';
// @ts-ignore
import type EmojiService from 'embercom/services/emoji-service';
import { type ConversationResource } from '../conversation-resource';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { type TableEntryHighlights } from 'embercom/objects/inbox/conversation-table-entry';
import { type PublicAPI } from 'embercom/components/inbox2/conversation-reply-composer';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { type SendAndCloseFn } from 'embercom/objects/inbox/types/composer';
import type SmartReplyResource from '../smart-reply-resource';
import { TicketCategory } from 'embercom/objects/inbox/ticket';

interface Args {
  conversationResource: ConversationResource;
  panelContainer?: HTMLElement;
  highlights?: TableEntryHighlights;
  sendAndClose: SendAndCloseFn;
  smartReplyResource: SmartReplyResource;
  isNotePane: boolean;
  canShowSendAndCloseHint: boolean;
  dismissSendAndCloseHint: () => void;
  isLivePhoneCall: boolean;
  onComposerReady?: (composerAPI: PublicAPI) => void;
}

interface Signature {
  Args: Args;
}

export default class TicketPanelComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare conversationStreamSettings: ConversationStreamSettings;
  @service declare emojiService: EmojiService;
  @service declare inboxState: InboxState;
  @trackedReset('args.conversationResource') currentlyVisible = this.conversation.isReplyable;
  @tracked fadeStyle: string;
  @service declare inboxHotkeys: InboxHotkeys;

  readonly ComposerLocation = ComposerLocation;
  private composerAPI?: PublicAPI;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.fadeStyle = this.calculateFadeStyle;
  }

  get conversation(): Conversation {
    return this.args.conversationResource.conversation;
  }

  get highlights() {
    return this.args.conversationResource.highlights;
  }

  get isConversationReplyable(): boolean {
    return this.conversation.isReplyable;
  }

  get isNotReplyableTicketModel(): boolean {
    return !this.conversation.isReplyable;
  }

  get hideEvents() {
    return this.conversationStreamSettings.hideEvents;
  }

  get showTicketAttributesPanel() {
    return !this.currentlyVisible;
  }

  get isTicketNotesComposer() {
    return this.conversation.isTicket && !this.conversation.isReplyable;
  }

  get showTicketStream() {
    return (
      !this.conversation.isReplyable &&
      this.session.workspace.isFeatureEnabled('ticket-stream-restyling')
    );
  }

  get ticketPanelContainer() {
    return this.args.panelContainer;
  }

  get scrollDisabled() {
    return !!(
      this.conversation.ticketCategory === TicketCategory.Tracker ||
      this.conversation.ticketCategory === TicketCategory.Task
    );
  }

  get calculateFadeStyle() {
    let ticketStreamHeader = document.getElementsByClassName(
      'inbox2__ticket-page__activity-header',
    )[0];
    if (ticketStreamHeader && this.conversation.isReplyable) {
      let coords = ticketStreamHeader.getBoundingClientRect();
      return `top: ${coords.height}px; height: 16px;`;
    }
    return '';
  }

  @action updateStyle() {
    this.fadeStyle = this.calculateFadeStyle;
  }

  @action
  ensureScrollInBottomWhenTyping() {
    if (this.ticketPanelContainer) {
      this.ticketPanelContainer.scrollTo({
        top: this.ticketPanelContainer.scrollHeight,
        behavior: 'smooth',
      });
    }
  }

  @action onComposerReady(composerAPI: PublicAPI) {
    this.composerAPI = composerAPI;
    this.args.onComposerReady?.(composerAPI);
  }

  @action focusComposer(e: KeyboardEvent) {
    this.inboxHotkeys.handleFocusWithin(() => this.composerAPI?.focus(), '[data-list-in-focus]', e);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationPage::TicketPanel': typeof TicketPanelComponent;
    'inbox2/conversation-page/ticket-panel': typeof TicketPanelComponent;
  }
}
