/* import __COLOCATED_TEMPLATE__ from './join-copilot-waitlist.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-ai */
import templateOnlyComponent from '@ember/component/template-only';

export interface Args {
  onSkip: () => void;
  shouldRenderSkipButton: boolean;
  trackLearnMoreClicked: () => void;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

const JoinCopilotWaitlist = templateOnlyComponent<Signature>();
export default JoinCopilotWaitlist;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Setup::JoinCopilotWaitlist': typeof JoinCopilotWaitlist;
    'inbox2/copilot/setup/join-copilot-waitlist': typeof JoinCopilotWaitlist;
  }
}
