/* import __COLOCATED_TEMPLATE__ from './available-fin-actions.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type AvailableFinActions from 'embercom/objects/inbox/renderable/available-fin-actions';
import LinkWithText from 'embercom/helpers/link-with-text';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

interface AvailableFinActionsPart extends RenderablePart {
  renderableData: AvailableFinActions;
}

interface Args {
  part: AvailableFinActionsPart;
}

interface Signature {
  Args: Args;
}

export default class AvailableFinActionsPartComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;

  // @ts-ignore helper doesn't seem to be typed correctly
  linkWithTextHelper = LinkWithText.create(this);

  get actionLinks() {
    if (this.actions.length > 0) {
      return this.actions.map((action) => {
        return this.linkWithTextHelper.compute([
          'apps.app.settings.workflow-connector-actions.custom-action',
          action.id,
          action.name,
        ]);
      });
    }
    return [];
  }

  get actions() {
    return this.args.part.renderableData.actions;
  }

  get firstActionLink() {
    return this.actionLinks[0];
  }

  get firstActionName() {
    return this.actions[0].name;
  }

  get additionalActionNumber() {
    return this.actions.length - 1;
  }

  get renderableData() {
    return this.args.part.renderableData;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::AvailableFinActions': typeof AvailableFinActionsPartComponent;
    'inbox2/conversation-stream/event-parts/available-fin-actions': typeof AvailableFinActionsPartComponent;
  }
}
