/* import __COLOCATED_TEMPLATE__ from './pane.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { type ChildAction } from 'embercom/objects/inbox/command-k/action';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';
import { useResource } from 'ember-resources';
import NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import type UpdateTicketStateAction from 'embercom/objects/inbox/command-k/update-ticket-state';
import type CommandKService from 'embercom/services/command-k';
import { type CommandKPaneComponentArgs } from 'embercom/lib/inbox2/command-k-types';
import { NamedIcon } from 'embercom/objects/inbox/command-k/icons';
import type InboxState from 'embercom/services/inbox-state';
import type Conversation from 'embercom/objects/inbox/conversation';
import { type TicketSystemState as TicketSystemStateType } from 'embercom/objects/inbox/conversation';
import type TicketStateService from 'embercom/services/ticket-state-service';
import { groupCustomStatesBySystemState } from 'embercom/lib/inbox2/ticket-custom-states';

type Args = CommandKPaneComponentArgs<UpdateTicketStateAction>;

interface Signature {
  Args: Args;
}

export default class UpdateTicketStatePaneComponent extends Component<Signature> {
  @service declare commandK: CommandKService;
  @service declare intl: IntlService;
  @service declare inboxState: InboxState;
  @service declare ticketStateService: TicketStateService;

  @tracked query = '';

  selection = useResource(this, NavigableSelection, () => ({
    items: this.items,
    isCommandKVisible: this.commandK.isVisible,
  }));

  get items(): Array<ChildAction> {
    let customStates: Array<ChildAction> = this.args.actionItem.buildResults(this.query);

    return groupCustomStatesBySystemState(customStates, this.ticketStateService);
  }

  @action showUserLabel(action: ChildAction): boolean {
    let currentConversation: Conversation =
      this.args.actionItem.context.conversation || this.inboxState.activeConversation;
    let isNotCloseState = !action.shortcuts?.display?.length && action.id !== 'close';
    let isShared = !!currentConversation?.visibleToUser;
    let customState = this.ticketStateService.getTicketCustomStateById(action.value);
    return isNotCloseState && isShared && customState !== undefined;
  }

  isNamedIcon(icon: unknown): icon is NamedIcon {
    return icon instanceof NamedIcon;
  }

  @action getTicketSystemState(action: ChildAction): TicketSystemStateType {
    return this.ticketStateService.getTicketCustomStateById(action.value)?.systemState;
  }

  @action getUserLabelOfCustomState(action: ChildAction): string {
    return this.ticketStateService.getTicketCustomStateById(action.value).userLabel;
  }

  @action search(query: string) {
    this.query = query;

    this.commandK.trackSearch({
      query: this.query,
      number_of_results: this.items.length,
    });
  }

  @action onSelect(childAction: ChildAction, _: any, emberEvent?: any) {
    emberEvent?.stopPropagation();
    emberEvent?.stopImmediatePropagation();
    this.args.onSelect(childAction);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CommandK::UpdateTicketState::Pane': typeof UpdateTicketStatePaneComponent;
    'inbox2/command-k/update-ticket-state/pane': typeof UpdateTicketStatePaneComponent;
  }
}
