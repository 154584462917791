/* import __COLOCATED_TEMPLATE__ from './copilot-composer.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-ai */
import Component from '@glimmer/component';
import { modifier } from 'ember-modifier';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { Breakpoints, isElementNarrow } from 'embercom/lib/inbox2/copilot/breakpoints';
import type InboxState from 'embercom/services/inbox-state';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
export interface CopilotComposerArgs {
  inputValue: string;
  updateText: (event: InputEvent & { target: HTMLTextAreaElement }) => void;
  sendMessage: () => Promise<void>;
  isDisabled: boolean;
  tooltipContent: string | undefined;
  conversationLength?: number;
  isCopilotVisible: boolean;
}

interface Signature {
  Element: Element;
  Args: CopilotComposerArgs;
  Blocks: {
    filters: [];
    default: [];
  };
}

export default class CopilotComposer extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare inboxState: InboxState;
  @service declare inboxHotkeys: InboxHotkeys;

  @tracked isComposerNarrow = false;
  @tracked isComposerFocused = false;

  autoResizeModifier = modifier(
    (
      element: HTMLTextAreaElement,
      _positional,
      {
        inputValue,
      }: {
        inputValue?: string;
      },
    ) => {
      element.style.height = 'auto';
      if (this.args.isCopilotVisible) {
        element.style.height = `${element.scrollHeight}px`;
      }
      return () => {
        // we don't do anything with this inputValue, we want this modifier to
        //  recompute based on the source of truth input value, so we no-op
        // evaluate it here. the same as `let a = 1; a;`
        inputValue;
      };
    },
  );

  get isTooltipEnabled() {
    return this.args.tooltipContent !== undefined;
  }

  get placeholderText() {
    if (this.args.conversationLength && this.args.conversationLength > 0) {
      let key = this.isComposerNarrow
        ? 'inbox.ask-fin.ask-a-follow-up-question-short'
        : 'inbox.ask-fin.ask-a-follow-up-question';

      return this.intl.t(key);
    } else {
      return this.intl.t('inbox.ask-fin.ask-a-question');
    }
  }

  @action onComposerResize() {
    if (this.inboxState.sidebarWidthInPixels === Breakpoints.closed) {
      return;
    }

    this.isComposerNarrow = isElementNarrow(this.inboxState.sidebarWidthInPixels);
  }

  @action onFocus() {
    this.isComposerFocused = true;
  }

  @action onBlur() {
    this.isComposerFocused = false;
  }

  @action handleSendHotkey() {
    if (this.isComposerFocused) {
      this.args.sendMessage();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Composer::CopilotComposer': typeof CopilotComposer;
    'inbox2/copilot/composer/copilot-composer': typeof CopilotComposer;
  }
}
