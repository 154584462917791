/* import __COLOCATED_TEMPLATE__ from './suggestion-pills.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-ai */
import Component from '@glimmer/component';
import { type InboxAssistantConversationPart } from 'embercom/objects/inbox/inbox-assistant-conversation';
import { type TrackedArray } from 'tracked-built-ins';
import type IntlService from 'embercom/services/intl';
import { inject as service } from '@ember/service';

interface SuggestionPillsArgs {
  conversationParts: TrackedArray<InboxAssistantConversationPart> | undefined;
  updateComposerText: (text: string) => void;
  extractedQuestion?: string;
  activeConversationId?: number;
}

interface Signature {
  Element: Element;
  Args: SuggestionPillsArgs;
  Blocks: {
    default: [];
  };
}

export default class SuggestionPills extends Component<Signature> {
  @service declare intl: IntlService;

  get pills() {
    let pills: { text: string; id: string; action: (text: string) => void; argument: string }[] =
      [];
    let conversationParts = this.args.conversationParts;
    if (conversationParts?.length) {
      let lastPart = conversationParts[conversationParts.length - 1];
      let conversationId = lastPart?.metadata?.conversation_id;
      if (conversationId === this.args.activeConversationId && lastPart?.isFromAssistant) {
        pills = [
          {
            text: this.intl.t('inbox.ask-fin.thread-state.translate'),
            id: 'translate',
            action: this.args.updateComposerText,
            argument: this.intl.t('inbox.ask-fin.thread-state.translate-prompt'),
          },
          {
            text: this.intl.t('inbox.ask-fin.thread-state.friendly-prompt'),
            id: 'friendly',
            action: this.args.updateComposerText,
            argument: this.intl.t('inbox.ask-fin.thread-state.friendly-prompt'),
          },
          {
            text: this.intl.t('inbox.ask-fin.thread-state.reply'),
            id: 'reply',
            action: this.args.updateComposerText,
            argument: this.intl.t('inbox.ask-fin.thread-state.reply-prompt'),
          },
        ];
        return pills;
      }
    }
    pills = [
      {
        text: this.intl.t('inbox.ask-fin.empty-state.summarize'),
        id: 'summarize',
        action: this.args.updateComposerText,
        argument: this.intl.t('inbox.ask-fin.empty-state.summarize'),
      },
      {
        text: this.intl.t('inbox.ask-fin.empty-state.what-can-you-do'),
        id: 'what-can-you-do',
        action: this.args.updateComposerText,
        argument: this.intl.t('inbox.ask-fin.empty-state.what-can-you-do'),
      },
    ];
    if (this.args.extractedQuestion) {
      pills.unshift({
        text: this.args.extractedQuestion,
        id: 'extracted-question',
        action: this.args.updateComposerText,
        argument: this.args.extractedQuestion,
      });
    }
    return pills;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::SuggestionPills': typeof SuggestionPills;
    'inbox2/copilot/suggestion-pills': typeof SuggestionPills;
  }
}
