/* import __COLOCATED_TEMPLATE__ from './call-listening-modal.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import type TwilioService from 'embercom/services/twilio-service';
import type IntlService from 'embercom/services/intl';
import type InboxState from 'embercom/services/inbox-state';
import { action } from '@ember/object';
import type PhoneNumber from 'embercom/models/inbox/phone-number';
import { tracked } from '@glimmer/tracking';
import type IntercomCallService from 'embercom/services/intercom-call-service';
import { REGULATORY_BUNDLE_COUNTRY_CODES } from 'embercom/models/settings/calling';

interface Args {}

interface Signature {
  Args: Args;
}

export default class CallListeningModal extends Component<Signature> {
  @service declare twilioService: TwilioService;
  @service declare intercomCallService: IntercomCallService;
  @service declare intl: IntlService;
  @service declare inboxState: InboxState;

  @tracked workspacePhoneNumber?: PhoneNumber;
  @tracked adminOnCall?: AdminSummary;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.acceptCall();
  }

  get userSummary() {
    return this.twilioService.userSummary;
  }

  get customerLabelText() {
    return this.intl.t('calling.modal.listening.customer-label', {
      name: this.userSummary?.displayAs,
      number: this.userSummary?.phone,
    });
  }

  get workspacePhoneNumberText() {
    let phoneNumbersFlag = REGULATORY_BUNDLE_COUNTRY_CODES.get(
      this.twilioService.calledNumberCountryCode as string,
    )?.flag;
    return this.intl.t('calling.modal.listening.workplace-phone-number', {
      flag: phoneNumbersFlag,
      name: this.twilioService.calledNumberCountryCode,
    });
  }

  get isCallStillActive() {
    return this.twilioService.isActiveCall;
  }

  @action async acceptCall() {
    await this.twilioService.acceptListeningCall(this.inboxState.activeConversationId);
    this.adminOnCall = this.twilioService?.conversation?.adminAssignee;
  }

  @action
  async stopListening() {
    if (this.intercomCallService.listeningCallId !== undefined) {
      await this.intercomCallService.stopListeningToCallInProgress(
        this.intercomCallService.listeningCallId,
      );
    }
  }

  @action
  goToConversation() {
    this.twilioService.navigateToConversation();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CallListeningModal': typeof CallListeningModal;
    'inbox2/call-listening-alert': typeof CallListeningModal;
  }
}
