/* eslint-disable @intercom/intercom/no-component-inheritance */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import { Resource, type Named } from 'ember-resources';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type ComposerPane from 'embercom/objects/inbox/composer-pane';
import ReplyPane from 'embercom/objects/inbox/reply-pane';
import NotePane from 'embercom/objects/inbox/note-pane';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import storage from 'embercom/vendor/intercom/storage';
import type Session from 'embercom/services/session';
import { setOwner } from '@ember/application';
import { type Channel } from 'embercom/models/data/inbox/channels';

interface ComposerPaneResourceArgs {
  conversationId: number;
  replyChannel?: Channel;
  defaultToNotePane: boolean;
  isConversationLoading: boolean;
  switchToNotePane?: boolean;
  isTicketNotesComposer?: boolean;
  hideNotePane?: boolean;
}

export default class ComposerPaneResource extends Resource<Named<ComposerPaneResourceArgs>> {
  @service declare session: Session;

  @tracked activePane!: ComposerPane;
  @tracked replyPane: ReplyPane;
  @tracked notePane: ComposerPane;
  conversationIdArg: number;
  isConversationLoading: boolean;
  switchToNotePane?: boolean;
  hideNotePane?: boolean;
  emptyBlocksDoc = new BlocksDocument([]);
  private replyChannel?: Channel;

  constructor(owner: any, args: Named<ComposerPaneResourceArgs>, previous: ComposerPaneResource) {
    super(owner, args, previous);

    // Workaround for a bug:
    // https://github.com/NullVoxPopuli/ember-resources/issues/195
    this.conversationIdArg = this.args.named.conversationId;
    this.replyChannel = this.args.named.replyChannel;
    this.isConversationLoading = this.args.named.isConversationLoading;
    this.switchToNotePane = this.args.named.switchToNotePane;
    this.hideNotePane = this.args.named.hideNotePane;

    if (
      previous &&
      previous.conversationIdArg === this.conversationIdArg &&
      previous.replyChannel === this.replyChannel &&
      // We want to rebuild the resource if switchToNotePane differs to reactively force the note pane.
      // We don't need to rebuild the resource if defaultToNotePane differs since we only want to check
      // that when we first load the composer for a conversation.
      // However the value of defaultToNotePane is not settled until the conversation is loaded, so we
      // need to rebuild the resource to check it again once it has loaded.
      // This code assumes that defaultToNotePane only relies on the loading of the conversation.
      previous.isConversationLoading === this.isConversationLoading &&
      previous.switchToNotePane === this.switchToNotePane
    ) {
      this.replyPane = previous.replyPane;
      this.notePane = previous.notePane;
      this.activePane = previous.activePane;
    } else {
      this.replyPane = new ReplyPane(this.replyChannel);
      this.notePane = new NotePane(this.args.named.isTicketNotesComposer);
      if (this.paneHasContent(this.replyPane)) {
        this.setActivePane(this.replyPane);
      } else if (this.paneHasContent(this.notePane)) {
        this.setActivePane(this.notePane);
      } else if (this.args.named.defaultToNotePane || this.switchToNotePane) {
        this.setActivePane(this.notePane);
      } else {
        this.setActivePane(this.replyPane);
      }
    }

    setOwner(this.replyPane, owner);
    setOwner(this.notePane, owner);
  }

  get listPanes(): ComposerPane[] {
    if (this.hideNotePane) {
      return [this.replyPane];
    }
    return [this.replyPane, this.notePane];
  }

  setActivePane(pane: ComposerPane, blocksDoc?: BlocksDocument) {
    this.activePane = pane;

    if (blocksDoc) {
      return this.setActivePaneDocBlock(blocksDoc);
    }

    this.setActivePaneDocBlock(this.fetchStoredBlocksDoc(this.activePane));
  }

  setActivePaneDocBlock(blocksDoc: BlocksDocument) {
    this.activePane.blocksDoc = blocksDoc;
  }

  setPaneBlocksDocAndStore(pane: ComposerPane, blocksDoc: BlocksDocument) {
    let { blocks } = blocksDoc;
    this.setActivePaneDocBlock(blocksDoc);
    if (
      blocks.length === 0 ||
      (blocks.length === 1 && blocks[0].type === 'paragraph' && blocks[0].text === '')
    ) {
      this.deletePaneBlocksDoc(pane);
    } else {
      this.storeBlocksDoc(pane, blocksDoc);
    }
  }

  deletePaneBlocksDoc(pane: ComposerPane, conversationId?: number) {
    this.deleteStoredBlocksDoc(pane, conversationId || this.args.named.conversationId);
  }

  private paneHasContent(pane: ComposerPane) {
    return this.fetchStoredBlocksDoc(pane).blocks.length > 0;
  }

  private fetchStoredBlocksDoc(pane: ComposerPane) {
    let storageKey = this.storageKeyForPane(pane, this.args.named.conversationId);
    let blocks = storage.get(storageKey);
    if (blocks) {
      return new BlocksDocument(blocks);
    } else {
      return this.emptyBlocksDoc;
    }
  }

  private storeBlocksDoc(pane: ComposerPane, blocksDoc: BlocksDocument) {
    let storageKey = this.storageKeyForPane(pane, this.args.named.conversationId);
    storage.set(storageKey, blocksDoc.blocks);
  }

  private deleteStoredBlocksDoc(pane: ComposerPane, conversationId: number) {
    let storageKey = this.storageKeyForPane(pane, conversationId);
    storage.remove(storageKey);
  }

  private storageKeyForPane(pane: ComposerPane, conversationId: number) {
    return `conversation-pane-draft-${this.session.workspace.id}-${conversationId}-${pane.type}`;
  }
}
