/* import __COLOCATED_TEMPLATE__ from './source.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-ai */

import Component from '@glimmer/component';

import {
  CopilotSourceSection,
  type CopilotInteractionMetadata,
  type NormalizedFinSource,
} from 'embercom/lib/inbox2/copilot/types';
import { KNOWLEDGE_HUB_ENTITIES_NAMES } from 'embercom/lib/knowledge-hub/constants';
import { EntityType } from 'embercom/models/data/entity-types';

export interface SourceArgs {
  source?: NormalizedFinSource;
  tooltipElement?: HTMLElement;
  section: CopilotSourceSection;
  insertBlocks?: () => void;
  isHighlightedPreviewSource?: boolean;
}

interface Signature {
  Element: Element;
  Args: SourceArgs;
  Blocks: {
    default: [];
  };
}

export default class SourceComponent extends Component<Signature> {
  get interactionMetadata(): CopilotInteractionMetadata {
    return {
      isFromRelatedContentSheet: this.isListSource,
    };
  }

  get isPreviewSource() {
    return this.args.section === CopilotSourceSection.SourcePreview;
  }

  get isInlineSource() {
    return this.args.section === CopilotSourceSection.InlinePreview;
  }

  get isListSource() {
    return this.args.section === CopilotSourceSection.List;
  }

  get entityName() {
    let entityType = this.args.source?.entity_type;

    switch (entityType) {
      case EntityType.InternalArticle:
        return 'internal_article';
      case EntityType.ArticleContent:
        return KNOWLEDGE_HUB_ENTITIES_NAMES[EntityType.ArticleContent];
      case EntityType.PastConversationSnippet:
      case EntityType.ConversationExcerpt:
        return 'conversation';
      case EntityType.SlackThread:
        return 'slack_thread';
      case EntityType.FileSourceContent:
        return KNOWLEDGE_HUB_ENTITIES_NAMES[EntityType.FileSourceContent];
      case EntityType.ExternalContent:
        return KNOWLEDGE_HUB_ENTITIES_NAMES[EntityType.ExternalContent];
      case EntityType.ContentSnippet:
        return KNOWLEDGE_HUB_ENTITIES_NAMES[EntityType.ContentSnippet];
      case EntityType.SavedReply:
        return 'saved_reply';
      default:
        throw new Error('Unknown entity type');
    }
  }

  get iconClassNames() {
    let entityType = this.args.source?.entity_type;

    switch (entityType) {
      case EntityType.InternalArticle:
        return 'bg-yellow-light text-yellow-dark dark:bg-dm-yellow-dark dark:text-yellow-light';
      case EntityType.ConversationExcerpt:
        return 'bg-sky text-blue-dark dark:bg-dm-blue-dark dark:text-dm-blue-light';
      default:
        return 'bg-gray-lightest text-dark dark:bg-dm-gray-dark dark:text-white';
    }
  }

  get pillClassNames() {
    let entityType = this.args.source?.entity_type;

    switch (entityType) {
      case EntityType.InternalArticle:
        return 'bg-yellow-light text-yellow-dark';
      default:
        return 'bg-off-white text-black';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Copilot::Sources::Source': typeof SourceComponent;
    'inbox2/copilot/sources/ource': typeof SourceComponent;
  }
}
