/* import __COLOCATED_TEMPLATE__ from './bot-email-reply.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import type PartGroup from 'embercom/objects/inbox/conversation-stream/part-group';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
import type Conversation from 'embercom/objects/inbox/conversation';
import type BotComment from 'embercom/objects/inbox/renderable/bot-comment';
import type FinAnswer from 'embercom/objects/inbox/renderable/fin-answer';
import AdminSummary from 'embercom/objects/inbox/admin-summary';
import { type TableEntryHighlights } from 'embercom/objects/inbox/conversation-table-entry';
import { type RenderableType } from 'embercom/models/data/inbox/renderable-types';

interface BotCommentRenderablePart extends RenderablePart {
  renderableType: RenderableType.BotComment;
  renderableData: BotComment;
}

interface FinAnswerRenderablePart extends RenderablePart {
  renderableType: RenderableType.FinAnswer;
  renderableData: FinAnswer;
}

type BotEmailReplyRenderablePart = BotCommentRenderablePart | FinAnswerRenderablePart;

export interface BotEmailReplyPartGroup extends PartGroup {
  parts: Array<BotEmailReplyRenderablePart>;
}

interface Args {
  partGroup: BotEmailReplyPartGroup;
  conversation: Conversation;
  isReadOnly: boolean;
  isTicketStream: boolean;
  tinyAvatar?: boolean;
  highlightedConversationPartId?: number;
  highlights?: TableEntryHighlights;
}

interface Signature {
  Args: Args;
}

export default class ConversationStreamPartGroupsBotEmailReplyComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;

  get renderableData() {
    return this.args.partGroup.parts.lastObject!.renderableData;
  }

  get avatarObject(): AdminSummary {
    if (this.workflowSummary) {
      return new AdminSummary(
        parseInt(this.workflowSummary.id, 10),
        this.intl.t('inbox.custom-bot', { name: this.workflowSummary.title }),
        this.renderableData.entity.imageURL,
      );
    } else {
      return this.renderableData.creatingEntity.object;
    }
  }

  get avatarRoute() {
    if (this.workflowSummary) {
      return 'apps.app.operator.custom-bots.custom-bot.show';
    } else {
      return 'apps.app.admins.admin';
    }
  }

  get workflowSummary() {
    return 'customBotSummary' in this.renderableData && this.renderableData.customBotSummary;
  }

  get lastPartSeenByUser() {
    let { conversation } = this.args;

    return (
      conversation?.lastSeenByUserAt &&
      conversation?.lastSeenByUserAt >= this.lastPartInGroup.createdAt
    );
  }

  get lastPartInGroup() {
    let { parts } = this.args.partGroup;
    return parts[parts.length - 1];
  }

  nextPartIsRelated = (part: BotEmailReplyRenderablePart) => {
    let { parts } = this.args.partGroup;
    let index = parts.indexOf(part);
    if (index === parts.length - 1) {
      return false;
    }
    let nextPart = parts[index + 1];
    return (
      'relatedPartId' in nextPart.renderableData &&
      nextPart.renderableData.relatedPartId === part.entityId
    );
  };

  previousPartIsRelated = (part: BotEmailReplyRenderablePart) => {
    let { parts } = this.args.partGroup;
    let index = parts.indexOf(part);
    return (
      index > 0 &&
      'relatedPartId' in part.renderableData &&
      parts[index - 1].entityId === part.renderableData.relatedPartId
    );
  };

  isRatingRequest = (part: BotEmailReplyRenderablePart) => {
    return 'isRatingRequest' in part.renderableData && part.renderableData.isRatingRequest;
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::PartGroups::BotEmailReply': typeof ConversationStreamPartGroupsBotEmailReplyComponent;
    'inbox2/conversation-stream/part-groups/bot-email-reply': typeof ConversationStreamPartGroupsBotEmailReplyComponent;
  }
}
