/* import __COLOCATED_TEMPLATE__ from './call-options.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { get } from 'embercom/lib/ajax';
import type IntlService from 'embercom/services/intl';
import type VideoCallService from 'embercom/services/video-call-service';
import { type Channel } from 'embercom/models/data/inbox/channels';
import {
  channelIsMobile,
  replyChannelIsChat,
  ReplyChannelMap,
} from 'embercom/objects/inbox/composer-pane';
import type TwilioService from 'embercom/services/twilio-service';
import type User from 'embercom/objects/inbox/user';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import { taskFor } from 'ember-concurrency-ts';
import { task, dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import type Session from 'embercom/services/session';
import type Calling from 'embercom/models/settings/calling';
import type Store from '@ember-data/store';
import type PhoneNumber from 'embercom/models/inbox/phone-number';
import type ArrayProxy from '@ember/array/proxy';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import type RealtimeUsersService from 'embercom/services/realtime-users';
import type ParticipantUserSummary from 'embercom/objects/inbox/participant-user-summary';
import { type GroupedOptionable, type Optionable } from './common/dropdown-button';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { trackedReset } from 'tracked-toolbox';

interface Args {
  conversation: Conversation;
  user: User;
  iconOnly?: boolean;
}

interface Signature {
  Args: Args;
}

enum DropdownOption {
  userInfo = 'user-info',
  phoneLine = 'phone-line',
  phoneCall = 'phone-call',
  videoCall = 'video-call',
  voiceCall = 'voice-call',
}

export type CallDropdownOption = Optionable & {
  text?: string;
  additionalText?: string;
  user?: UserSummary;
  icon?: InterfaceIconName;
  tooltipText?: string | null;
  items?: Array<Optionable>;
};

type CallGroupedDropdownOption = GroupedOptionable<CallDropdownOption> & {
  items?: Array<CallDropdownOption>;
};

export default class CallOptionsDropdownComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare twilioService: TwilioService;
  @service declare videoCallService: VideoCallService;
  @service declare notificationsService: any;
  @service declare session: Session;
  // eslint-disable-next-line @intercom/intercom/service-allow-list
  @service declare store: Store;
  @service declare realtimeUsers: RealtimeUsersService;

  @tracked callSettings: Calling | null = null;
  @tracked workspacePhoneNumbers: ArrayProxy<PhoneNumber> | undefined;
  @tracked selectedWorkspacePhoneNumber: PhoneNumber | undefined;

  @trackedReset({
    memo: 'args.conversation.firstParticipant.id',
    update(_component: CallOptionsDropdownComponent, _key: string, last: string | undefined) {
      return this.updateSelectedUser(this.args.conversation.firstParticipant.id, last);
    },
  })
  selectedUser?: UserSummary;

  @tracked isLoading = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.loadCallSettings).perform().value;
    taskFor(this.getWorkspacePhoneNumbers).perform();
  }

  @task({ restartable: true })
  *loadCallSettings(): TaskGenerator<void> {
    let response = yield get(`/ember/inbox/calling_settings`, {
      app_id: this.session.workspace.id,
    }) as Calling;
    this.store.pushPayload({ 'settings/calling': response });
    this.callSettings = this.store.peekRecord('settings/calling', response.id);
  }

  @dropTask
  *getWorkspacePhoneNumbers() {
    this.workspacePhoneNumbers = yield this.store.findAll('inbox/phone-number');
    let latestWorkspacePhoneNumber = this.args.conversation.attributes?.find(
      (attr) => attr.descriptor.id === 'workspace_phone_number',
    )?.value as string;
    this.selectedWorkspacePhoneNumber =
      this.workspacePhoneNumbers?.findBy('phoneNumber', latestWorkspacePhoneNumber) ||
      this.workspacePhoneNumbers?.firstObject;
  }

  updateSelectedUser(id: string, previous: UserSummary | undefined) {
    if (previous) {
      this.realtimeUsers.untrack(previous.id);
    }

    let foundUser = this.args.conversation.participantSummaries.findBy('id', id);

    if (foundUser) {
      this.realtimeUsers.track(foundUser.id);
    }

    return foundUser;
  }

  get userSummary(): UserSummary {
    return this.args.conversation.userSummary;
  }

  get selectedUserFirstName() {
    return this.selectedUser?.firstName ?? '';
  }

  get selectedUserDisplayAs() {
    return this.selectedUser?.displayAs ?? '';
  }

  get userInfoDropdownItem(): CallDropdownOption {
    return {
      id: DropdownOption.userInfo,
      text: this.selectedUserDisplayAs,
      user: this.selectedUser,
      items: this.userOptions,
      selected: this.selectedUser?.id,
    };
  }

  get phoneLineDropdownItem(): CallDropdownOption {
    let selectedWorkspaceNumberText = '-';

    if (this.selectedWorkspacePhoneNumber) {
      selectedWorkspaceNumberText = this.getNameFromNumber(this.selectedWorkspacePhoneNumber);
      let selectedWorkspaceNumber =
        parsePhoneNumberFromString(
          this.selectedWorkspacePhoneNumber.phoneNumber,
        )?.formatInternational() || this.selectedWorkspacePhoneNumber.phoneNumber;

      selectedWorkspaceNumberText += ` ${selectedWorkspaceNumber}`;
    }

    return {
      id: DropdownOption.phoneLine,
      text: selectedWorkspaceNumberText,
      items: this.workspaceNumberOptions,
      selected: this.selectedWorkspacePhoneNumber?.id,
    };
  }

  get actionDropdownItems(): CallDropdownOption[] {
    let formattedUserNumber =
      parsePhoneNumberFromString(this.phoneNumber)?.formatInternational() || this.phoneNumber;

    let isMessengerDisabled = this.disableMessengerCalls;
    let disableMessengerCallsReason = this.disableMessengerCallsReason;

    return [
      {
        id: DropdownOption.phoneCall,
        text: `${this.intl.t('calling.call')} ${this.selectedUserFirstName || this.selectedUserDisplayAs} ${formattedUserNumber}`,
        icon: 'phone',
        isDisabled: this.disablePhoneCalls,
        tooltipText: this.disablePhoneCallsReason,
        onSelect: async () => await this.startPhoneCall(),
      },
      {
        id: DropdownOption.voiceCall,
        text: this.intl.t('calling.voice-call'),
        icon: 'phone',
        isDisabled: isMessengerDisabled,
        tooltipText: disableMessengerCallsReason,
        onSelect: async () => await this.startVideoCall(false),
      },
      {
        id: DropdownOption.videoCall,
        text: this.intl.t('calling.video-call'),
        icon: 'video-camera',
        isDisabled: isMessengerDisabled,
        tooltipText: disableMessengerCallsReason,
        onSelect: async () => await this.startVideoCall(true),
      },
    ];
  }

  get groupedOptions(): CallGroupedDropdownOption[] {
    return [
      {
        id: 'selection-options',
        items: [this.userInfoDropdownItem, this.phoneLineDropdownItem],
      },
      {
        id: 'action-items',
        items: this.actionDropdownItems,
      },
    ];
  }

  get workspaceNumberOptions(): CallDropdownOption[] {
    if (this.workspacePhoneNumbers?.length) {
      return this.workspacePhoneNumbers.map((number: PhoneNumber) => {
        return {
          id: number.id,
          text: this.getNameFromNumber(number),
          additionalText: parsePhoneNumberFromString(number.phoneNumber)?.formatInternational(),
          onSelect: () => this.onNumberSelectionChange(number.id),
        } as CallDropdownOption;
      });
    } else {
      return [];
    }
  }

  get userOptions(): CallDropdownOption[] {
    if (this.args.conversation.hasMultipleParticipants) {
      return this.args.conversation.participantSummaries.map((user: ParticipantUserSummary) => {
        return {
          id: user.id,
          text: user.displayAs,
          user,
          additionalText: user.phone
            ? parsePhoneNumberFromString(user.phone)?.formatInternational()
            : '-',
          onSelect: () => this.onUserSelection(user.id),
        };
      });
    } else {
      return [];
    }
  }

  get userPhone() {
    return this.selectedUser?.phone;
  }

  get phoneNumber(): string {
    let number = this.userPhone;

    if (number !== undefined && number?.toString()?.trim().length > 0) {
      return number;
    } else {
      return '';
    }
  }

  getNameFromNumber(number: PhoneNumber) {
    let numberName = number.name || '';
    return `${numberName}`;
  }

  get disablePhoneCalls() {
    return (
      !this.callSettings?.outboundEnabled ||
      !this.workspacePhoneNumbers?.length ||
      !this.twilioService.isInitialized ||
      this.twilioService.adminLacksMicrophonePermissions ||
      !this.phoneNumber ||
      this.isLoading ||
      this.twilioService.isActiveCall ||
      this.videoCallService.isActiveCall
    );
  }

  get disablePhoneCallsReason() {
    let disablePhoneCallsReason = null;

    if (!this.phoneNumber) {
      disablePhoneCallsReason = this.intl.t('calling.disabled-reason.no-phone-number');
    } else if (!this.workspacePhoneNumbers?.length) {
      disablePhoneCallsReason = this.intl.t('calling.disabled-reason.no-outbound-phone-numbers');
    } else if (!this.twilioService.isInitialized) {
      disablePhoneCallsReason = this.intl.t('calling.disabled-reason.calling-service');
    } else if (this.twilioService.isActiveCall || this.videoCallService.isActiveCall) {
      disablePhoneCallsReason = this.intl.t('calling.disabled-reason.ongoing-call');
    } else if (this.twilioService.adminLacksMicrophonePermissions) {
      disablePhoneCallsReason = this.intl.t('calling.disabled-reason.no-mic-permissions');
    } else if (!this.callSettings?.outboundEnabled) {
      disablePhoneCallsReason = this.intl.t('calling.disabled-reason.no-outbound');
    }

    return disablePhoneCallsReason;
  }

  get disableMessengerCallsReason() {
    let disableMessengerCallsReason = null;

    if (!this.isUserActive) {
      disableMessengerCallsReason = this.intl.t('calling.disabled-reason.user-inactive');
    } else if (channelIsMobile(this.args.conversation.channel.replyChannel)) {
      disableMessengerCallsReason = this.intl.t('calling.disabled-reason.mobile-channel');
    }

    return disableMessengerCallsReason;
  }

  get replyChannel() {
    return this.args.conversation.channel.replyChannel;
  }

  get disableMessengerCalls() {
    let currentChannel: Channel = this.replyChannel;
    let currentReplyChannel = ReplyChannelMap[currentChannel];

    return (
      !replyChannelIsChat(currentReplyChannel) ||
      channelIsMobile(currentChannel) ||
      !this.isUserActive() ||
      this.isLoading ||
      this.twilioService.isActiveCall ||
      this.videoCallService.isActiveCall
    );
  }

  isUserActive() {
    if (this.selectedUser?.id) {
      return this.realtimeUsers.activeUsers.has(this.selectedUser.id);
    }
    return false;
  }

  @action onNumberSelectionChange(id: string) {
    this.selectedWorkspacePhoneNumber = this.workspacePhoneNumbers?.findBy('id', id);
  }

  @action onUserSelection(id: string) {
    this.selectedUser = this.updateSelectedUser(id, this.selectedUser);
  }

  async checkAndHandlePermissions(isVideoRoom: boolean) {
    await navigator.mediaDevices
      .getUserMedia({ audio: true, video: isVideoRoom })
      .catch(function (err) {
        throw err;
      });
  }

  async startPhoneCall() {
    this.isLoading = true;

    if (!this.selectedUser) {
      console.error('No user selected');
    } else {
      await this.twilioService.callNumber(
        this.phoneNumber,
        this.args.conversation,
        this.selectedUser,
        this.selectedWorkspacePhoneNumber?.phoneNumber,
      );
      this.twilioService.calledNumberCountryCode =
        this.selectedWorkspacePhoneNumber?.countryIsoCode;
      this.twilioService.conversation = this.args.conversation;
    }

    this.isLoading = false;
  }

  async startVideoCall(isVideoRoom: boolean) {
    try {
      this.isLoading = true;
      await this.checkAndHandlePermissions(isVideoRoom);

      let room = await this.videoCallService.createRoom(this.args.conversation?.id, isVideoRoom);

      if (room) {
        this.videoCallService.setupParticipantsTracks();
        this.videoCallService.user = this.selectedUser;
      }
    } catch (err) {
      switch (err.name) {
        case 'NotAllowedError':
          this.notificationsService.notifyError(
            this.intl.t('calling.errors.calling-permissions-error'),
          );
          break;
        case 'NotFoundError':
          this.notificationsService.notifyError(this.intl.t('calling.errors.calling-device-error'));
          break;
        default:
          this.notificationsService.notifyError(
            this.intl.t('calling.errors.calling-connection-error'),
          );
          break;
      }
    } finally {
      this.isLoading = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::CallOptions': typeof CallOptionsDropdownComponent;
    'inbox2/call-options': typeof CallOptionsDropdownComponent;
  }
}
