/* import __COLOCATED_TEMPLATE__ from './conversations-table-resource.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type Named, Resource, useResource } from 'ember-resources';
import {
  InboxMentionsStatus,
  InboxStateOption,
  isConversationInInbox,
  isConversationStateEqual,
} from 'embercom/models/data/inbox/inbox-filters';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import { isSameInbox } from 'embercom/objects/inbox/inboxes/inbox';
import { type SortState } from '../conversations-table';
import { registerDestructor } from '@ember/destroyable';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import type InboxApi from 'embercom/services/inbox-api';
import { inject as service } from '@ember/service';
import { intersection, isEmpty, isEqual, random } from 'underscore';
import { timeout } from 'ember-concurrency';
import { action } from '@ember/object';
import type InboxState from 'embercom/services/inbox-state';
import { type Router } from '@ember/routing';
import type Session from 'embercom/services/session';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { cancel, later } from '@ember/runloop';
import { type EmberRunTimer } from '@ember/runloop/types';
import type Inbox2Counters from 'embercom/services/inbox2-counters';
import { type InboxContentsChangedEvent } from 'embercom/services/nexus';
import type Nexus from 'embercom/services/nexus';
import { NexusEventName, NexusFallbackPoller } from 'embercom/services/nexus';
import { type UpdateMessage } from 'embercom/services/conversation-updates';
import type ConversationUpdates from 'embercom/services/conversation-updates';
import { type ConversationIdsWithContext } from '../conversation-list-inbox-resource';
import { cached } from 'tracked-toolbox';
import Component from '@glimmer/component';
import { type ConversationsTableData } from 'embercom/components/inbox2/conversations-table';
import ENV from 'embercom/config/environment';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type Tracing from 'embercom/services/tracing';

const SCHEDULE_SYNC_JITTER = 200;
const CONVERSATIONS_UPDATE_TIMEOUT = ENV.APP._2000MS;

export const CONVERSATIONS_PER_PAGE = 30;

interface ApiResponse {
  total: number;
  conversations: ConversationTableEntry[];
  validFor: number;
}

type Args = {
  inbox?: Inbox;
  selectedStateOption: InboxStateOption;
  selectedMentionsStatus: InboxMentionsStatus;
  selectedSortOptionForTable: SortState;
  selectedConversations: number;
  fields: string[];
  onConversationStateChanged?: (
    conversation: ConversationTableEntry,
    nextNavigableConversation?: ConversationTableEntry,
  ) => Promise<unknown>;
};

export class ConversationsTableResource
  extends Resource<Named<Args>>
  implements ConversationsTableData
{
  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;
  @service declare router: Router;
  @service declare session: Session;
  @service declare inbox2Counters: Inbox2Counters;
  @service declare tracing: Tracing;
  @service declare nexus: Nexus;
  @service declare conversationUpdates: ConversationUpdates;
  @service declare intercomEventService: any;

  @tracked private localConversations: ConversationTableEntry[] = [];
  @tracked private transitions: ConversationTableEntry[] = [];
  @tracked private transitionedLocalConversationIds = new Set<number>();

  @tracked totalConversationsCount = 0;
  @tracked isInitialLoad = true;
  @tracked isStreamPaused = false;

  private inbox?: Inbox;
  private selectedStateOption: InboxStateOption;
  private selectedSortOptionForTable: SortState;
  private selectedMentionsStatus: InboxMentionsStatus;
  private fields: string[];
  private count: number = CONVERSATIONS_PER_PAGE;
  private completedInitialLoad = false;

  private scheduledSync?: EmberRunTimer;
  private poller = new NexusFallbackPoller(this);

  constructor(owner: any, args: Named<Args>, previous?: ConversationsTableResource) {
    super(owner, args, previous);

    let {
      inbox,
      selectedStateOption,
      selectedSortOptionForTable,
      selectedMentionsStatus,
      fields,
      selectedConversations,
    } = args.named;

    // Workaround for https://github.com/NullVoxPopuli/ember-resources/issues/195
    this.inbox = inbox;
    this.selectedStateOption = selectedStateOption;
    this.selectedMentionsStatus = selectedMentionsStatus;
    this.selectedSortOptionForTable = selectedSortOptionForTable;
    this.fields = fields;

    if (isEmpty(this.fields)) {
      return;
    }

    let canCopyPrevious = previous !== undefined && this.canCopyPrevious(previous);
    if (canCopyPrevious && previous !== undefined) {
      this.localConversations = previous.localConversations;
      this.transitions = previous.transitions;
      this.totalConversationsCount = previous.totalConversationsCount;
      this.count = previous.count;
      this.isInitialLoad = previous.isInitialLoad;
      this.completedInitialLoad = previous.completedInitialLoad;
      this.transitionedLocalConversationIds = previous.transitionedLocalConversationIds;
    } else {
      this.loadConversations();
    }

    if (this.inbox) {
      this.poller.start(() => {
        this.loadConversations({ isBackgroundReload: true });
      });
      this.conversationUpdates.subscribe(this.applyConversationUpdates);

      this.nexus.subscribeTopics([`inbox/${this.inbox.type}/${this.inbox.id}`]);
      this.nexus.addListener(NexusEventName.InboxContentsChanged, this.notifyUpdates);
    }

    registerDestructor(this, () => {
      [
        this.fetchConversationsDebounced,
        this.fetchConversations,
        this.backgroundReloadConversations,
        this.foregroundReloadConversations,
      ].forEach((task) => taskFor(task).cancelAll());
      this.cancelScheduledSync();
      this.poller.stop();

      if (this.inbox) {
        this.nexus.removeListener(NexusEventName.InboxContentsChanged, this.notifyUpdates);
        this.nexus.unsubscribeTopics([`inbox/${this.inbox.type}/${this.inbox.id}`]);
        this.conversationUpdates.unsubscribe(this.applyConversationUpdates);
      }
    });

    if (selectedConversations) {
      this.pauseUpdates();
    } else if (previous?.isStreamPaused) {
      this.resumeUpdates();
    }
  }

  get inboxConversationsCount() {
    if (this.inbox && this.shouldUpdateCounters()) {
      return this.inbox2Counters.countForInbox(this.inbox);
    } else {
      return this.totalConversationsCount;
    }
  }

  @cached
  get conversations() {
    // For mentions Inbox, we don't need to sort locally.
    if (this.isMentionsInbox) {
      return this.localConversations;
    }

    let conversations = this.localConversations.filter((conversation) => {
      let context = this.conversationIdsWithContext[conversation.id];
      // Redacted conversations will have an empty value for state, so we don't
      // have a way to tell if they are in context. We treat them as if they are.
      let isInContext =
        (conversation.redacted || context.stateMatches) &&
        context.inboxMatches &&
        !context.transitioned;
      return isInContext || conversation.id === this.inboxState.activeConversationId;
    });

    let { sortField, direction } = this.selectedSortOptionForTable;
    if (sortField === 'sorting_updated_at' && direction === 'asc') {
      conversations = conversations.sortBy('lastUpdated');
    } else if (sortField === 'sorting_updated_at' && direction === 'desc') {
      conversations = conversations.sortBy('lastUpdated').reverse();
    }

    return conversations;
  }

  @cached
  private get conversationIdsWithContext(): ConversationIdsWithContext {
    let idsWithContext: ConversationIdsWithContext = {};

    this.localConversations.forEach((conversation) => {
      idsWithContext[conversation.id] = {
        stateMatches: isConversationStateEqual(
          this.selectedStateOption,
          conversation.state!,
          conversation.ticketState,
        ),
        inboxMatches: isConversationInInbox(this.inbox, conversation),
        transitioned: this.transitionedLocalConversationIds.has(conversation.id),
      };
    });

    return idsWithContext;
  }

  get selectedConversations() {
    return this.conversations.filter((c: ConversationTableEntry) =>
      this.inboxState.selectedConversations.ids.includes(c.id),
    );
  }

  private canCopyPrevious(previous: ConversationsTableResource) {
    return (
      previous &&
      isSameInbox(this.inbox, previous.inbox) &&
      this.selectedStateOption === previous.selectedStateOption &&
      this.selectedMentionsStatus === previous.selectedMentionsStatus &&
      isEqual(this.selectedSortOptionForTable, previous.selectedSortOptionForTable) &&
      this.fields.length === intersection(this.fields, previous.fields).length &&
      previous.completedInitialLoad
    );
  }

  get canLoadMore() {
    return this.nextCount > this.count;
  }

  @action loadMore(metadata: Record<string, any>) {
    if (!this.canLoadMore) {
      return;
    }

    this.count = this.nextCount;
    this.loadConversations();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'scrolled',
      object: 'conversations',
      section: 'conversation_table',
      ...metadata,
    });
  }

  @action reload() {
    this.loadConversations();
  }

  /**
   * Api calls
   */

  private loadConversations({ isBackgroundReload } = { isBackgroundReload: false }) {
    if (this.inbox) {
      taskFor(this.fetchConversationsDebounced).perform({ isBackgroundReload });
    }
  }

  @task private *fetchConversations() {
    if (!this.inbox) {
      return;
    }

    let state: InboxStateOption | undefined = this.selectedStateOption;
    let mentionsStatus: InboxMentionsStatus | undefined = this.selectedMentionsStatus;

    if (this.inbox.category === InboxCategory.Shared && this.inbox.id === 'mentions') {
      // For the mentions inbox, we'll load all conversations: open, closed, snoozed.
      state = undefined;
    } else {
      // For non-mentions inboxes, mentionsStatus is not a valid param.
      mentionsStatus = undefined;
    }

    let { conversations, total, validFor } = (yield this.inboxApi.fetchTableConversationsForInbox(
      this.inbox.category,
      this.inbox.id,
      state,
      this.fields,
      this.count,
      {
        sort_field: this.selectedSortOptionForTable.sortField,
        sort_direction: this.selectedSortOptionForTable.direction,
      },
      mentionsStatus,
    )) as ApiResponse;

    let { activeConversationId } = this.inboxState;

    this.transitionedLocalConversationIds = new Set();

    // On reload, it's possible that the current conversation is not in the
    // list. In that case, we want to add it back and mark it as transitioned.
    if (activeConversationId && !conversations.any((c) => c.id === activeConversationId)) {
      let idx = this.findActiveIndex();
      let conversation = this.conversations[idx];
      if (conversation) {
        conversations.insertAt(Math.min(idx, conversations.length), conversation);
        this.transitionedLocalConversationIds = new Set([activeConversationId]);
      }
    }

    // Preserve local updates by only replacing conversations with more recent incoming updates
    this.localConversations = conversations.map((conversation) => {
      this.conversationUpdates
        .updatesAfter(conversation.id, conversation.lastUpdated)
        .forEach((update) => update.apply(conversation));
      return conversation;
    });

    this.totalConversationsCount = total;

    if (this.inbox && this.shouldUpdateCounters()) {
      this.inbox2Counters.updateCount(this.inbox, total);
    }

    if (validFor) {
      this.scheduleNextSync(validFor);
    }
    if (this.isInitialLoad) {
      this.isInitialLoad = false;
    }
  }

  private shouldUpdateCounters(): boolean {
    if (!this.inbox) {
      return false;
    }

    if (this.args.named.inbox?.type === InboxType.Mentions) {
      if (this.selectedMentionsStatus === InboxMentionsStatus.Unread) {
        return true;
      }
    } else if (this.selectedStateOption === InboxStateOption.Open) {
      return true;
    }

    return false;
  }

  @action
  private async applyConversationUpdates(updates: UpdateMessage[]) {
    let conversationsWithStateChanges: Set<
      [ConversationTableEntry, ConversationTableEntry | undefined]
    > = new Set();

    updates.forEach((update) => {
      let conversation = this.localConversations.find(
        (conversation) => conversation.id === update.conversationId,
      );

      // Find the index of the conversation in the list of visible conversations,
      // so that we can decide the previous / next conversation.
      let idx = this.conversations.findIndex(
        (conversation) => conversation.id === update.conversationId,
      );
      if (!conversation) {
        return;
      }

      let nextConversation = this.conversations[idx + 1];
      let previousConversation = this.conversations[idx - 1];

      if (update.type === 'added') {
        update.entries.forEach((entry) => {
          entry.apply(conversation!);

          if (entry.part.renderableType === RenderableType.StateChange) {
            conversationsWithStateChanges.add([
              conversation!,
              nextConversation ?? previousConversation,
            ]);
          }
        });
        this.maybeUpdateCounters(conversation);
      } else if (update.type === 'removed') {
        update.entries.forEach((entry) => entry.rollback(conversation!));
      }
    });

    for (let [conversation, nextNavigableConversation] of conversationsWithStateChanges.values()) {
      await this.args.named.onConversationStateChanged?.(conversation, nextNavigableConversation);
    }
  }

  private maybeUpdateCounters(conversation: ConversationTableEntry) {
    if (!this.inbox || this.isMentionsInbox) {
      return;
    }

    // if we've been assigned out of this inbox, decrement the counter
    let inboxMatches = isConversationInInbox(this.inbox, conversation);
    if (!inboxMatches) {
      this.inbox2Counters.decrementCount(this.inbox);
      return;
    }

    // if the conversation has been closed / snoozed, decrement the counter
    if (this.selectedStateOption === InboxStateOption.Open) {
      let conversationIsOpen = isConversationStateEqual(
        InboxStateOption.Open,
        conversation.state!,
        conversation.ticketState,
      );

      if (!conversationIsOpen) {
        this.inbox2Counters.decrementCount(this.inbox);
      }
      return;
    }

    // if the conversation has been re-opened, increment the counter
    if (
      this.selectedStateOption === InboxStateOption.Closed ||
      this.selectedStateOption === InboxStateOption.Snoozed
    ) {
      let conversationIsOpen = isConversationStateEqual(
        InboxStateOption.Open,
        conversation.state!,
        conversation.ticketState,
      );

      if (conversationIsOpen) {
        this.inbox2Counters.incrementCount(this.inbox);
      }
      return;
    }
  }

  private get isMentionsInbox() {
    return this.inbox?.type === InboxType.Mentions;
  }

  private scheduleNextSync(validForInSeconds: number, jitter = SCHEDULE_SYNC_JITTER) {
    this.cancelScheduledSync();
    this.scheduledSync = later(
      this,
      () => this.loadConversations({ isBackgroundReload: true }),
      validForInSeconds * 1000 + random(jitter),
    );
  }

  private cancelScheduledSync() {
    this.scheduledSync && cancel(this.scheduledSync);
  }

  @task({ keepLatest: true }) *fetchConversationsDebounced(
    { isBackgroundReload } = { isBackgroundReload: false },
  ) {
    yield taskFor(
      isBackgroundReload ? this.backgroundReloadConversations : this.foregroundReloadConversations,
    )
      .linked()
      .perform();
    if (!this.completedInitialLoad) {
      this.completedInitialLoad = true;
    }
    yield timeout(CONVERSATIONS_UPDATE_TIMEOUT);
  }

  @task({ keepLatest: true }) private *backgroundReloadConversations() {
    yield taskFor(this.fetchConversations).linked().perform();
  }

  @task({ keepLatest: true }) private *foregroundReloadConversations() {
    yield taskFor(this.fetchConversations).linked().perform();
  }

  /**
   * State
   */

  get isLoading() {
    return taskFor(this.fetchConversations).isRunning;
  }

  get isLoadingInForeground() {
    return taskFor(this.foregroundReloadConversations).isRunning;
  }

  get hasError() {
    return (!this.isLoading && taskFor(this.fetchConversations).last?.isError) ?? false;
  }

  get hasConversations() {
    return this.conversations.length > 0;
  }

  get nextCount() {
    return Math.min(
      this.localConversations.length + CONVERSATIONS_PER_PAGE,
      this.totalConversationsCount,
    );
  }

  get countAdditionalConversationsBeingFetched() {
    if (!this.isLoading) {
      return 0;
    }

    return this.count - this.localConversations.length;
  }

  @action private notifyUpdates(event: InboxContentsChangedEvent) {
    let data = event.eventData;
    let currentInboxChanged = data?.inboxes.any((inbox) =>
      isSameInbox(inbox, this.args.named.inbox),
    );

    if (currentInboxChanged && !this.isStreamPaused) {
      this.loadConversations({ isBackgroundReload: true });
    }
  }

  private pauseUpdates() {
    this.isStreamPaused = true;
  }

  private resumeUpdates() {
    this.isStreamPaused = false;
    this.loadConversations({ isBackgroundReload: true });
  }

  /**
   * Navigation
   */

  get active() {
    return this.conversations.find(({ id }) => this.inboxState.activeConversationId === id);
  }

  getRelativeConversation(position: number): ConversationTableEntry | null {
    let index = this.findActiveIndex() + position;
    if (index >= 0 && index < this.conversations.length) {
      return this.conversations[index];
    }

    return null;
  }

  private findActiveIndex() {
    return this.conversations.findIndex(({ id }) => id === this.inboxState.activeConversationId);
  }

  /**
   * Conversations
   */

  @action remove({ id: idToRemove }: { id: number }) {
    let conversation = this.conversations.findBy('id', idToRemove);
    if (!conversation) {
      return;
    }

    this.transitions = [...this.transitions, conversation];
  }
}

export default class ConversationsTableResourceComponent extends Component<{
  Args: Args;
  Blocks: {
    default: [ConversationsTableResource];
  };
}> {
  resource = useResource(this, ConversationsTableResource, () => {
    return { ...this.args };
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationsTable::ConversationsTableResource': typeof ConversationsTableResourceComponent;
    'inbox2/conversations-table/conversations-table-resource': typeof ConversationsTableResourceComponent;
  }
}
